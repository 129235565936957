import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

const ContractList = (row, index, CRMContract, object_key, CRMRepresentative, isContractOpenCollapse, customer_id) => {
  return CRMContract?.filter((CurrentContract) => CurrentContract[object_key] === row?.id || (object_key === "ticket_id" && CurrentContract?.id === row?.contract_id)).map(CurrentContract => {
    return (
      <>
        {isContractOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isContractOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Contract Name</b><br /> <a href={"/admin/crmcontractdetail?customer=" + customer_id + "&id=" + CurrentContract?.crm_customers_id + "&peopleid=" + CurrentContract?.people_id + "&contractid=" + CurrentContract?.id}>{CurrentContract?.contract_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Contract Value</b><br /> {parseFloat(CurrentContract?.contract_value?.replace(/,/g, '')).toLocaleString('en-US', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Contract</b><br /> <a href={CurrentContract?.contract_url} target="_blank">Link</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Contract Status</b><br /> {CurrentContract?.contract_status}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === CurrentContract?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
                </div>
              </Collapse>
            </TableCell >
          </TableRow >
        )}
      </>
    )
  })
};

export default ContractList;
