import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";

import CardAvatar from "components/Card/CardAvatar";
import Avatar from "components/Avatar";
import Dropzone from "components/Dropzone";

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import CustomInput from "components/CustomInput/CustomInput";
import SaveButton from "components/CustomButtons/Button";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Select from "components/Select/Select";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import AddUpdateTicket from "./AddUpdateTicket";
import SearchResults from "./SearchResults";
import LatestActivity from "./LatestActivity";
import UpdateCRMProduct from "views/Dashboard/UpdateCRMProduct";
import UpdateCRMService from "views/Dashboard/UpdateCRMService";
import UpdateRepresentativeProfile from "views/Dashboard/UpdateRepresentativeProfile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CustomersDefaultImage from "assets/img/Customers.png";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LinearNoContentWrapper from "components/LinearNoContentWrapper";
import { startCase, snakeCase } from "lodash";
import FormatPhone from "../Format/FormatPhone";

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CRM_CUSTOMERS,
  LIST_CURRENT_SOCIALS,
  UPDATE_CURRENT_SOCIALS,
  LIST_ALL_SOCIALS,
  REMOVE_CUSTOMER_SOCIAL,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  GET_ONBOARDING_ID,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  UPDATE_CRM_ACTIVITY,
  UPDATE_CRM_PHOTO,
  LIST_PHOTOS,
  LIST_SERVICES,
  LIST_PRODUCT,
  CREATE_TICKET,
  LIST_TICKETS,
  LIST_TICKET_PRIORITIES,
  LIST_TICKET_TYPES,
  LIST_TICKET_NOTES,
  LIST_TICKET_STATUSES,
  GET_CONTRACT_STATUS,
  UPDATE_CUSTOMER_EMAIL,
  LIST_CUSTOMERS,
  GET_FOLLOWING,
  UPDATE_FOLLOWING,
  GET_CUSTOMER_STATUS,
  GET_FORM_DATA,
  GET_CALL_DATA,
  GET_SIC_CODES,
  GET_NAICS_CODES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { LIST_CUSTOMER_USERS } from "queries/users";

import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CRMTicketDetail = (props) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const [name, setName] = React.useState('');
  const [value, setValue] = React.useState('');
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [SKU, setSKU] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [officephone, setOfficePhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [type, setType] = React.useState('');
  const [service, setService] = React.useState('');
  const [searchrepresentative, setSearchRepresentative] = React.useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [CRMCustomersSearch, setCRMCustomersSearch] = useState(null);
  const [CRMLeadsSearch, setCRMLeadsSearch] = useState(null);
  const [ShowAll, setShowAll] = useState(true);
  const [ShowCustomers, setShowCustomers] = useState(false);
  const [ShowLeads, setShowLeads] = useState(false);
  const [ShowPeople, setShowPeople] = useState(false);
  const [ShowJobs, setShowJobs] = useState(false);
  const [ShowGalleries, setShowGalleries] = useState(false);
  const [ShowPhotos, setShowPhotos] = useState(false);
  const [ShowProducts, setShowProducts] = useState(false);
  const [ShowServices, setShowServices] = useState(false);
  const [ShowContracts, setShowContracts] = useState(false);
  const [ShowDocuments, setShowDocuments] = useState(false);
  const [ShowProposals, setShowProposals] = useState(false);
  const [ShowReps, setShowReps] = useState(false);

  const [CRMPeopleSearch, setCRMPeopleSearch] = useState(null);
  const [CRMJobSearch, setCRMJobSearch] = useState(null);
  const [CRMGallerySearch, setCRMGallerySearch] = useState(null);
  const [CRMPhotosSearch, setCRMPhotosSearch] = useState(null);
  const [CRMDocumentSearch, setCRMDocumentSearch] = useState(null);
  const [CRMContractSearch, setCRMContractSearch] = useState(null);
  const [CRMProposalSearch, setCRMProposalSearch] = useState(null);
  const [CRMProductSearch, setCRMProductSearch] = useState(null);
  const [CRMServiceSearch, setCRMServiceSearch] = useState(null);
  const [CRMRepresentativeSearch, setCRMRepresentativeSearch] = useState(null);
  const [UpdatePhoto, setUpdatePhoto] = useState([]);
  const [searchstring, setsearchstring] = useState(null);
  const [updateRep, setupdateRep] = useState(null);

  const [SICCodes, setSICCodes] = useState(null);
  const [NAICSCodes, setNAICSCodes] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);
  const [PeopleStates, setPeopleStates] = useState(null);
  const [PeopleCountries, setPeopleCountries] = useState(null);
  const [PeoplePostalCode, setPeoplePostalCode] = useState(null);
  const [PeopleCity, setPeopleCity] = useState(null);
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMCustomersReferrer, setCRMCustomersReferrer] = useState(null);
  const [ReferrerFound, setReferrerFound] = useState(null);
  const [referrer_name, setreferrer_name] = useState(null);
  const [personreferrer_name, setpersonreferrer_name] = useState(null);
  const [PersonReferrerFound, setPersonReferrerFound] = useState(null);
  const [CRMPersonReferrer, setCRMPersonReferrer] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [OriginalCRMCustomers, setOriginalCRMCustomers] = useState(null);
  const [CRMActivitySubmission, setCRMActivitySubmission] = useState({ "activity_completed": 1 });
  const [DocumentsubmissionData, setDocumentsubmissionData] = useState({});
  const [TicketsubmissionData, setTicketsubmissionData] = useState([]);
  const [ContractsubmissionData, setContractsubmissionData] = useState({});
  const [ProposalsubmissionData, setProposalsubmissionData] = useState({});
  const [GallerysubmissionData, setGallerysubmissionData] = useState({});
  const [JobsubmissionData, setJobsubmissionData] = useState({});
  const [ContractData, setContractData] = useState(false);
  const [PersonFound, setPersonFound] = useState(true);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [OnboardingID, setOnboardingID] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CallFilterImageSelected, setCallFilterImageSelected] = useState(false);
  const [EmailFilterImageSelected, setEmailFilterImageSelected] = useState(false);
  const [TextFilterImageSelected, setTextFilterImageSelected] = useState(false);
  const [NoteFilterImageSelected, setNoteFilterImageSelected] = useState(false);
  const [OnboardingFilterImageSelected, setOnboardingFilterImageSelected] = useState(false);
  const [EstimateFilterImageSelected, setEstimateFilterImageSelected] = useState(false);
  const [TicketFilterImageSelected, setTicketFilterImageSelected] = useState(false);
  const [MeetingFilterImageSelected, setMeetingFilterImageSelected] = useState(false);
  const [TaskFilterImageSelected, setTaskFilterImageSelected] = useState(false);
  const [DeadlineFilterImageSelected, setDeadlineFilterImageSelected] = useState(false);
  const [UploadFilterImageSelected, setUploadFilterImageSelected] = useState(false);
  const [JobFilterImageSelected, setJobFilterImageSelected] = useState(false);
  const [PhotoFilterImageSelected, setPhotoFilterImageSelected] = useState(false);
  const [ContractFilterImageSelected, setContractFilterImageSelected] = useState(false);
  const [ProposalFilterImageSelected, setProposalFilterImageSelected] = useState(false);
  const [InboundCallsFilterImageSelected, setInboundCallsFilterImageSelected] = useState(false);
  const [InboundFormsFilterImageSelected, setInboundFormsFilterImageSelected] = useState(false);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [AllSocials, setAllSocials] = useState(null);
  const [CRMSocials, setCRMSocials] = useState(null);
  const [highestSocialID, sethighestSocialID] = useState(1);
  const [activity_type, setactivity_type] = useState(null);
  const [crm_customer_id, setcrm_customer_id] = useState(null);
  const [crm_people_id, setcrm_people_id] = useState(null);
  const [crm_ticket_id, setcrm_ticket_id] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMProducts, setCRMProducts] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);

  const [CRMEstimate, setCRMEstimate] = useState(null);
  const [showMorePeople, setshowMorePeople] = useState(false);
  const [showMoreActivity, setshowMoreActivity] = useState(false);
  const [showMoreDocuments, setshowMoreDocuments] = useState(false);
  const [showMorePhotos, setshowMorePhotos] = useState(false);
  const [showMoreContracts, setshowMoreContracts] = useState(false);
  const [showMoreProposals, setshowMoreProposals] = useState(false);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketPriorites, setCRMTicketPriorites] = useState([]);
  const [CRMTicketTypes, setCRMTicketTypes] = useState([]);
  const [CRMTicketNotes, setCRMTicketNotes] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [user_id, setuser_id] = useState(null);
  const [Following, setFollowing] = useState(0);
  const [contractvalue, setcontractvalue] = useState('');
  const [Hover, setHover] = useState(false);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');
  const [PersonsubmissionData, setPersonSubmissionData] = useState(null);
  const [FollowingPeople, setFollowingPeople] = useState(0);
  const [CRMOriginalPeople, setCRMOriginalPeople] = useState(null);
  const [B2B, setB2B] = useState(null);
  const [placeholderText, setplaceholderText] = useState("   \uf002 Search");
  const [revenue, setrevenue] = useState('');
  const [FormData, setFormData] = useState([]);
  const [CallData, setCallData] = useState([]);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const navigate = useNavigate();
  const crm_user_level = me?.readMe?.crm_user_level;
  const user_level = me?.readMe?.user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const FormatCurrency = () => {
    let Customer_Revenue = "0";
    if (revenue !== null && revenue !== "")
      Customer_Revenue = revenue;
    let newValue = parseFloat(Customer_Revenue?.replaceAll(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setCRMCustomers({
      ...CRMCustomers,
      ["revenue"]: newValue
    });

    setrevenue(newValue);
  }

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null || value === "")
      value = "0";
    return parseFloat(value?.replaceAll(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateCustomerRevenueValueFieldHandler = (e) => {
    setrevenue(e.target.value);
  };

  const [GetFormDataQuery, { called }] = useLazyQuery(GET_FORM_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormData((data?.GetFormData || []).map(
        ({ form_data: form_data_str, ...rest }) => {
          const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
          const form_data_obj_normalized = {};
          Object.keys(form_data_obj).forEach(key => {
            const title = startCase(key.split("<")[0]);
            if (title === "G Recaptcha Response") {
              form_data_obj_normalized[title] = form_data_obj[key];
              return;
            }
            form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
          });
          return {
            form_data: form_data_obj_normalized,
            ...rest
          };
        }
      ))
    }
  });

  const [submitUpdateCRMFollowingRequest] = useMutation(
    UPDATE_FOLLOWING,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        if (crm_customer_id)
          setFollowing(data?.updateCRMFollowing);
        if (crm_people_id)
          setFollowingPeople(data?.updateCRMFollowing);
      }
    }
  );

  const [GetCallDataQuery, { called: callsqueried }] = useLazyQuery(GET_CALL_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCallData(data?.GetCallData || []);
    }
  });

  //  looks for inbound calls and forms so we can get there info
  let CallIDS = [];
  let FormIDS = [];
  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Form") {
      FormIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Call") {
      CallIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  let { data: SICCodes_data } = useQuery(GET_SIC_CODES);
  let { data: NAICSCodes_data } = useQuery(GET_NAICS_CODES);
  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  useQuery(GET_POSTAL_CODE,
    {
      skip: !CRMCustomers?.office_zip,
      variables: {
        name: CRMCustomers?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !CRMCustomers?.office_city,
      variables: {
        name: CRMCustomers?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  useQuery(GET_POSTAL_CODE,
    {
      skip: !PersonsubmissionData?.zip_code || PersonsubmissionData?.zip_code === CRMOriginalPeople?.zip_code,
      variables: {
        name: PersonsubmissionData?.zip_code
      },
      onCompleted: (data) => {
        setPeoplePostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !PersonsubmissionData?.city || PersonsubmissionData?.city === CRMOriginalPeople?.city,
      variables: {
        name: PersonsubmissionData?.city
      },
      onCompleted: (data) => {
        setPeopleCity(data?.getCity || []);
      }
    }
  );

  const updatePersonCountryFieldHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonStateHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonPostalCodeHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonCityHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  const [GetThisTicket] = useLazyQuery(LIST_TICKETS, {
    skip: !customer_id || !crm_ticket_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      ticket_id: crm_ticket_id
    },
    onCompleted: (data) => {
      setTicketsubmissionData(data?.ListTickets[0] || []);
    }
  });

  let { data: ticket_priorities_data } = useQuery(LIST_TICKET_PRIORITIES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_types_data } = useQuery(LIST_TICKET_TYPES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });
  const [ListTicketNotes] = useLazyQuery(LIST_TICKET_NOTES, {
    skip: !customer_id || !crm_ticket_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      ticket_id: crm_ticket_id
    },
    onCompleted: (data) => {
      setCRMTicketNotes(data?.ListTicketNotes || []);
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  useEffect(() => {
    if (called === false && FormIDS?.length) {
      GetFormDataQuery({
        variables: {
          customer_id,
          formIDS: FormIDS?.join(", ")
        }
      })
    }
    if (callsqueried === false && CallIDS?.length) {
      GetCallDataQuery({
        variables: {
          customer_id,
          callIDS: CallIDS?.join(", ")
        }
      })
    }
  }, [FormIDS, CallIDS]);

  const { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  let { data: Activity_data, refetch: refetchActivity } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Proposal_data, refetch: refetchProposal } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Onboarding_data, refetch: refetchOnboarding } = useQuery(GET_ONBOARDING_ID, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });


  let { data: Contract_data, refetch: refetchContracts } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Job_data, refetch: refetchJobs } = useQuery(LIST_JOBS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_Gallery_data, refetch: refetchGallery } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_data, refetch: refetchPhotos } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      id: crm_customer_id ? parseInt(crm_customer_id) : 0,
      status: -1,
    }
  });

  useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: referrer_name,
      status: -1,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMCustomersReferrer(data?.CRMCustomers || []);
    }
  });

  let { data: Representative_data, refetch: refetchRepresentatives } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data, refetch: refetchDocument } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
    }
  });

  let { data: people_data, refetch: refetchPeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !crm_people_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      id: crm_people_id
    }
  });

  let { data: social_data, refetch: refetchSocial } = useQuery(LIST_CURRENT_SOCIALS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id: crm_customer_id
    }
  });

  let { data: all_social_data } = useQuery(LIST_ALL_SOCIALS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    }
  });

  useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || (!personreferrer_name && !PersonsubmissionData?.referrer_id),
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: personreferrer_name,
      id: PersonsubmissionData?.referrer_id ? PersonsubmissionData?.referrer_id : null,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMPersonReferrer(data?.listCustomerEmails || []);
    }
  });

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: product_data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { loading: Customerloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B" || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 1,
      search: 1
    },
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });

  const [CustomerSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    skip: !crm_customer_id,
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });


  let { loading: Leadsloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B" || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  const [LeadSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    skip: !crm_customer_id,
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  let { loading: Peopleloading } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      cell_phone_number: phone,
      name,
      email,
      address,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  const [PeopleSearchQuery] = useLazyQuery(LIST_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  let { loading: Jobsloading } = useQuery(LIST_JOBS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      value,
      name,
      page: 0,
      rows_per_page: 3,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  const [JobSearchQuery] = useLazyQuery(LIST_JOBS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  let { loading: Galleryloading } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  const [GallerySearchQuery] = useLazyQuery(LIST_PHOTO_GALLERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  let { loading: Photosloading } = useQuery(LIST_PHOTOS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      description,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  const [PhotosSearchQuery] = useLazyQuery(LIST_PHOTOS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  let { loading: Documentsloading } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  const [DocumentSearchQuery] = useLazyQuery(LIST_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  let { loading: Contractsloading } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  const [ContractSearchQuery] = useLazyQuery(LIST_CONTRACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  let { loading: Proposalsloading } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const [ProposalSearchQuery] = useLazyQuery(LIST_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const { refetch: productrefetch, loading: Productsloading } = useQuery(LIST_PRODUCT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      price: !isNaN(+price) ? price : "",
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const [ProductSearchQuery] = useLazyQuery(LIST_PRODUCT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const { refetch: servicesrefetch, loading: Servicesloading } = useQuery(LIST_SERVICES, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  const [ServiceSearchQuery] = useLazyQuery(LIST_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  let { loading: Repsloading } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id || !name,
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      name: name ? name : "",
      search: 1
    },
    onCompleted: (data) => {
      setCRMRepresentativeSearch(data?.listCustomerRepresentatives?.filter((CurrentRep) =>
        CurrentRep?.first_name !== "Not" && CurrentRep?.first_name !== "Assigned")
        || [])
    }
  });

  const [RepresentativeSearchQuery] = useLazyQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMRepresentativeSearch(data?.listCustomerRepresentatives?.filter((CurrentRep) =>
        CurrentRep?.first_name !== "Not" && CurrentRep?.first_name !== "Assigned")
        || [])
    }
  });

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  let { data: ContractStatus_data } = useQuery(GET_CONTRACT_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id
    }
  });

  ContractStatus_data = ContractStatus_data?.getContractStatus || [];

  let ContractStatuses = [];

  if (ContractStatus_data) {
    ContractStatuses.push({ label: "select", value: 0 });
    ContractStatus_data.forEach(obj => {
      ContractStatuses.push({ label: obj.contract_status_text, value: obj.id })
    })
  }

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  let { data: CustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id || !CRMCustomers?.status,
    variables: {
      customer_id: customer_id,
      status: CRMCustomers?.status === 1 ? 1 : 0
    }
  });

  let { data: Following_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_customer_id,
    variables: {
      customer_id: customer_id,
      object_type: "Customer",
      object_id: crm_customer_id,
    }
  });

  let { data: FollowingPeople_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_people_id,
    variables: {
      customer_id: customer_id,
      object_type: "Person",
      object_id: crm_people_id,
    }
  });

  CustomerStatus_data = CustomerStatus_data?.getCustomerStatus || [];

  let CustomerStatuses = [];

  if (CustomerStatus_data) {
    CustomerStatuses.push({ label: "select", value: 0 });
    CustomerStatus_data.forEach(obj => {
      CustomerStatuses.push({ label: obj.customer_status_text, value: obj.id })
    })
  }

  useEffect(() => {
    if (CRMPeople && !PersonsubmissionData) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setPersonSubmissionData({
          ...CurrentPerson,
          ["office_phone_number"]: FormatPhone(CurrentPerson?.office_phone_number),
          ["cell_phone_number"]: FormatPhone(CurrentPerson?.cell_phone_number)
        })
      })
    }

    if (CRMPeople && !CRMOriginalPeople) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setCRMOriginalPeople({
          ...CurrentPerson,
          ["office_phone_number"]: FormatPhone(CurrentPerson?.office_phone_number),
          ["cell_phone_number"]: FormatPhone(CurrentPerson?.cell_phone_number),
        })
      })
    }

    if (CRMPersonReferrer?.length && PersonsubmissionData?.referrer_id && !PersonsubmissionData?.referrer_name) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setPersonSubmissionData({
          ...CurrentPerson,
          ["referrer_name"]: CurrentPerson?.referrer_id ? CRMPersonReferrer?.filter((CurrentPersonReferrer) =>
            CurrentPersonReferrer?.id === CurrentPerson?.referrer_id)?.map((CurrentPersonReferrer) => { setPersonReferrerFound(true); return (CurrentPersonReferrer?.first_name + " " + CurrentPersonReferrer?.last_name) })?.join("") : ""
        });

        setCRMOriginalPeople({
          ...CurrentPerson,
          ["referrer_name"]: CurrentPerson?.referrer_id ? CRMPersonReferrer?.filter((CurrentPersonReferrer) =>
            CurrentPersonReferrer?.id === CurrentPerson?.referrer_id)?.map((CurrentPersonReferrer) => { setPersonReferrerFound(true); return (CurrentPersonReferrer?.first_name + " " + CurrentPersonReferrer?.last_name) })?.join("") : ""
        });
      })
    }
  });

  useEffect(() => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);

    if (urlParams.get("id")) {
      setcrm_customer_id(parseInt(urlParams.get("id")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    if (urlParams.get("peopleid")) {
      setcrm_people_id(parseInt(urlParams.get("peopleid")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    if (urlParams.get("ticketid")) {
      setcrm_ticket_id(parseInt(urlParams.get("ticketid")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    setFollowing(Following_data?.getFollowing);
    setFollowingPeople(FollowingPeople_data?.getFollowing || []);

    setuser_id(me?.readMe?.id);

    let AssignedToUser = [];

    AssignedToUser = users?.listCustomerUsers;

    if (AssignedToUser && CRMAssignedToUsers?.length === 0) {
      AssignedToUser.push({ id: 0, first_name: "optional", last_name: "select" });
      setCRMAssignedToUsers(AssignedToUser);
    }

    setCRMCustomers({
      ...data?.CRMCustomers[0], ["office_phone_number"]: FormatPhone(data?.CRMCustomers[0]?.office_phone_number),
      ["referrer_name"]: data?.CRMCustomers[0]?.referrer_id ? CRMCustomersReferrer?.filter(CurrentCustomer =>
        CurrentCustomer?.id === data?.CRMCustomers[0]?.referrer_id)?.map((CurrentCustomer) => { setReferrerFound(true); return CurrentCustomer?.business_name })?.join("") : ""
    });

    setCRMSocials(social_data?.listCurrentSocials || []);
    setAllSocials(all_social_data?.listAllSocials || []);

    setSICCodes(SICCodes_data?.getSICCodes || []);
    setNAICSCodes(NAICSCodes_data?.getNAICSCodes || []);
    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);
    setPeopleStates(States_data?.getStates || []);
    setPeopleCountries(Country_data?.getCountries || []);

    setCRMActivity(Activity_data?.ListActivity || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setOnboardingID(Onboarding_data?.GetOnboardingID);

    setCRMContract(Contract_data?.ListContract || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMProducts(product_data?.ListProduct || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketPriorites(ticket_priorities_data?.ListTicketPriorities || []);
    setCRMTicketTypes(ticket_types_data?.ListTicketTypes || []);
    ListTicketNotes();
    GetThisTicket();

    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [data, Activity_data, Proposal_data, Contract_data, Job_data, Photo_Gallery_data, Representative_data,
    document_data, people_data, users, Photo_data, service_data, product_data, budget_data, Estimate_data,
    Following_data, FollowingPeople_data, ticket_priorities_data, ticket_types_data,
    ticket_statuses_data, all_ticket_data]);

  const handleOpen = () => {
    setUpdatedCustomerEmail({});
  };

  const handleClose = () => () => {
    setUpdatedCustomerEmail(null);
    refetchPeople();
  };

  const social_columns = [
    {
      Header: "Social",
      accessor: "social_channel"
    },
    {
      Header: "Remove"
    }
  ];

  function updateSocialssocial_channel_url(index, e) {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_url: e.target.value });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const updateSocialsocial_channel_id = (index) => e => {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_id: e });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const AddSocial = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestSocialID, social_channel_id: '', social_channel_url: '', people_id: null, crm_customer_id: crm_customer_id })
    sethighestSocialID(highestSocialID + 1)
    setCRMSocials({ ...CRMSocials, [Object.keys(CRMSocials)?.length]: NewArray });
  }

  const [updateSocials] = useMutation(
    UPDATE_CURRENT_SOCIALS,
    {
      skip: !state.customers?.selected?.id,
      skip: !CRMSocials
    });

  const [RemoveCustomerSocial] = useMutation(
    REMOVE_CUSTOMER_SOCIAL
  );

  const RemoveSocial = (key) => e => {
    RemoveCustomerSocial({
      fetchPolicy: "no-cache",
      variables: {
        customer_id: state.customers?.selected?.id,
        id: key
      },
      onCompleted: () => {
        setCRMSocials(Object.keys(CRMSocials)?.map((Social, index) => {
          if (CRMSocials[index].id !== key)
            return CRMSocials[index]
        })?.filter(Social => Social !== undefined))

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    });
  };

  const classes = useStyles();

  const [submitUpdateCRMPersonRequest] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: () => {
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
      }
    }
  );

  const updateFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.length) {
        CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.map((CurrentCustomer) => {
          setCRMCustomers({
            ...CRMCustomers,
            [key]: e.target.value,
            ["referrer_name"]: CurrentCustomer?.business_name
          });

          setreferrer_name(e.target.value);
          setReferrerFound(true);
        })
      }
      else {
        setCRMCustomers({
          ...CRMCustomers,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        setreferrer_name(e.target.value);
        setReferrerFound(false);
      }
    } else if (key === "assigned_to" || key === "customer_status") {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e
      });
    }
    else {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.value
      });
    }
  };

  const updateSICFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateCountryFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateReferrerHandler = (key, id, name) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: id,
      ["referrer_name"]: name
    });

    setReferrerFound(true);
  };

  const updatePersonReferrerHandler = (key, id, name) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: id,
      ["referrer_name"]: name
    });

    setPersonReferrerFound(true);
  };

  const updateCityHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateNAICSFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePersonFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMPersonReferrer?.filter(CurrentPerson => CurrentPerson?.business_name === e.target.value)?.length) {
        CRMPersonReferrer?.filter(CurrentPerson => CurrentPerson?.business_name === e.target.value)?.map((CurrentPerson) => {
          setPersonSubmissionData({
            ...PersonsubmissionData,
            [key]: e.target.value,
            ["referrer_name"]: CurrentPerson?.first_name + " " + CurrentPerson?.last_name
          });

          CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
            CurrentPerson[key] = e.target.value;
            CurrentPerson["referrer_name"] = CurrentPerson?.first_name + " " + CurrentPerson?.last_name;
          });

          setpersonreferrer_name(e.target.value);
          setPersonReferrerFound(true);
        })
      }
      else {
        setPersonSubmissionData({
          ...PersonsubmissionData,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
          CurrentPerson[key] = e.target.value;
          CurrentPerson["referrer_id"] = 0;
        });

        setpersonreferrer_name(e.target.value);
        setPersonReferrerFound(false);
      }
    } else if (key === "assigned_to") {
      setPersonSubmissionData({
        ...PersonsubmissionData,
        [key]: e
      });

      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        CurrentPerson[key] = e;
      });
    }
    else {
      setPersonSubmissionData({
        ...PersonsubmissionData,
        [key]: e.target.value
      });

      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        CurrentPerson[key] = e.target.value;
      });
    }
  };

  const [submitCreateCRMTicket] = useMutation(
    CREATE_TICKET,
    {
      onCompleted: async (TicketData) => {
        // If user added document activity notes
        if (CRMActivitySubmission) {

          //don't make activity make a notification that something updated the ticket
        }

        setDocumentsubmissionData({});
        setTicketsubmissionData([]);
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setCRMActivitySubmission({ "activity_completed": 1 });
        setuploadPercentage("");
        // go to the note tab to reload

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
        refetchTickets();
        ListTicketNotes();
        GetThisTicket();
        setCRMActivity(Activity_data?.ListActivity || []);
      }
    }
  );

  const updatePeopleNameFieldHandler = (value, object) => {
    if (object === "Contract") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setContractsubmissionData({
            ...ContractsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    } else if (object === "Document") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setDocumentsubmissionData({
            ...DocumentsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    } else if (object === "Proposal") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    } else if (object === "Photo") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setGallerysubmissionData({
            ...GallerysubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    } else if (object === "Job") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setJobsubmissionData({
            ...JobsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    } else if (object === "Activity") {
      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setCRMActivitySubmission({
            ...CRMActivitySubmission,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
          setcrm_people_id(CurrentPerson?.id);
        }
      })
    }
  };

  const updatePeopleFieldHandler = (key, object) => e => {
    if (object === "Contract") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    }
  };
  const updateActivityFieldHandler = (key) => e => {
    if (key === "activity_completed") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (key === "activity_date") {
      setCRMActivitySubmission({
        ...omit(CRMActivitySubmission, ["activity_completed"]),
        [key]: e.target.value,
        ["activity_completed"]: 0
      });
    } else if (key === "assigned_to" || key === "representative_id" || key === "activity_notes") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e
      });
    } else {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.value
      });
    }
  };

  const updatePersonDatabase = async (profile_image, lead_status, lead_type) => {
    if (PersonsubmissionData?.first_name === CRMOriginalPeople?.first_name &&
      PersonsubmissionData?.last_name === CRMOriginalPeople?.last_name &&
      PersonsubmissionData?.email === CRMOriginalPeople?.email &&
      PersonsubmissionData?.cell_phone_number === CRMOriginalPeople?.cell_phone_number &&
      PersonsubmissionData?.address_line_one === CRMOriginalPeople?.address_line_one &&
      PersonsubmissionData?.address_line_two === CRMOriginalPeople?.address_line_two &&
      PersonsubmissionData?.city === CRMOriginalPeople?.city &&
      PersonsubmissionData?.country === CRMOriginalPeople?.country &&
      PersonsubmissionData?.state === CRMOriginalPeople?.state &&
      PersonsubmissionData?.zip_code === CRMOriginalPeople?.zip_code &&
      PersonsubmissionData?.list_name === CRMOriginalPeople?.list_name &&
      PersonsubmissionData?.opt_in_text === CRMOriginalPeople?.opt_in_text &&
      PersonsubmissionData?.opt_in_voice === CRMOriginalPeople?.opt_in_voice &&
      PersonsubmissionData?.opt_int_email === CRMOriginalPeople?.opt_int_email &&
      PersonsubmissionData?.unsubscribe === CRMOriginalPeople?.unsubscribe &&
      PersonsubmissionData?.contact_business_only === CRMOriginalPeople?.contact_business_only &&
      PersonsubmissionData?.referrer_id === CRMOriginalPeople?.referrer_id &&
      PersonsubmissionData?.assigned_to === CRMOriginalPeople?.assigned_to &&
      (!profile_image && !lead_status && !lead_type)) {
      return
    }

    if (PersonsubmissionData || (profile_image || lead_status || lead_type)) {
      await submitUpdateCRMPersonRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(PersonsubmissionData, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text", "referrer_name"]),
            cell_phone_number: PersonsubmissionData?.cell_phone_number !== undefined ? PersonsubmissionData?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
            first_name: PersonsubmissionData?.first_name ? PersonsubmissionData?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
            id: crm_people_id,
            profile_image: profile_image ? profile_image : null,
            users_id: user_id,
            crm_representative_id: representative ? representative : 1,
            lead_type: lead_type ? lead_type : null,
            lead_status: lead_status ? lead_status : null
          }
        }
      });

      await submitUpdateCRMActivity({
        variables: {
          customer_id,
          ActivityData: {
            ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date"]),
            users_id: user_id,
            people_id: crm_people_id,
            activity_type: "Person",
            activity_description: "Updated Person " + PersonsubmissionData?.first_name + " " + PersonsubmissionData?.last_name,
            representative_id: PersonsubmissionData?.representative_id,
            crm_customers_id: crm_customer_id ? crm_customer_id : null,
            activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
          }
        }
      });


      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateDatabase = async (profile_image, customer_status) => {
    if (CRMCustomers?.business_name === OriginalCRMCustomers?.business_name &&
      CRMCustomers?.office_phone_number === OriginalCRMCustomers?.office_phone_number &&
      CRMCustomers?.office_address_line_one === OriginalCRMCustomers?.office_address_line_one &&
      CRMCustomers?.office_address_line_two === OriginalCRMCustomers?.office_address_line_two &&
      CRMCustomers?.office_city === OriginalCRMCustomers?.office_city &&
      CRMCustomers?.office_country === OriginalCRMCustomers?.office_country &&
      CRMCustomers?.office_state === OriginalCRMCustomers?.office_state &&
      CRMCustomers?.office_zip === OriginalCRMCustomers?.office_zip &&
      CRMCustomers?.website === OriginalCRMCustomers?.website &&
      CRMCustomers?.industry === OriginalCRMCustomers?.industry &&
      CRMCustomers?.revenue === OriginalCRMCustomers?.revenue &&
      CRMCustomers?.sic_code === OriginalCRMCustomers?.sic_code &&
      CRMCustomers?.naics_code === OriginalCRMCustomers?.naics_code &&
      CRMCustomers?.employees === OriginalCRMCustomers?.employees &&
      CRMCustomers?.established === OriginalCRMCustomers?.established &&
      !profile_image && !customer_status) {
      return
    }
    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers || profile_image) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status", "referrer_name"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replaceAll(/[^0-9.]+/g, "") : "",
            status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
            customer_status: customer_status ? customer_status : null,
            profile_image: profile_image ? profile_image : null,
            users_id: user_id,
            lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const handleSearchClose = () => () => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
  };

  const handleProductOpen = row => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentProduct(row?.id ? row : {});
  };

  const handleServiceOpen = row => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentService(row?.id ? row : {});
  };

  const handleRepOpen = row => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setupdateRep(row || {});
  };

  const handleRepClose = updated => {
    if (updated) {
      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setupdateRep(null);
  };

  const handleServiceClose = updated => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentService(null);
  };

  const handleProductClose = () => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentProduct(null);
  };

  const OpenPhotoEdit = (id) => {
    setName(null);
    setAddress(null);
    setPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setUpdatePhoto(CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })?.length ? CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })[0] : []);
  };

  const updatePhotoFieldHandler = key => (e) => {
    setUpdatePhoto({
      ...UpdatePhoto,
      [key]: e.target.value
    });
  };

  const handleSave = async () => {
    if (UpdatePhoto?.photo_name === undefined || UpdatePhoto?.photo_name === "") {
      alert("Photo Name is required");
      return false;
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (UpdatePhoto) {
      await submitUpdateCRMPhotoRequest({
        variables: {
          customer_id,
          photo_name: UpdatePhoto?.photo_name,
          photo_description: UpdatePhoto?.photo_description,
          photo_alt_text: UpdatePhoto?.photo_alt_text,
          id: UpdatePhoto?.id
        }
      });
    }

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    return true;
  };

  const [submitUpdateCRMPhotoRequest] = useMutation(
    UPDATE_CRM_PHOTO,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setUpdatePhoto([]);
      }
    }
  );

  const updateSearchHandler = () => e => {
    setsearchstring(e.target.value);

    if (e.target.value.length >= 2) {
      setName(e.target.value);
      setAddress(e.target.value);
      setPhone(e.target.value);
      setEmail(e.target.value);
      setOfficePhone(e.target.value);
      setService(e.target.value);
      setType(e.target.value);
      setDescription(e.target.value);
      setStatus(e.target.value);
      setSearchRepresentative(e.target.value);
      setValue(e.target.value);
      setStart(e.target.value);
      setEnd(e.target.value);
      setCategory(e.target.value);
      setSKU(e.target.value);
      setPrice(e.target.value);
    }
    else {
      setName(null);
      setAddress(null);
      setPhone(null);
      setSearchRepresentative(null);
      setEmail(null);
      setOfficePhone(null);
      setService(null);
      setType(null);
      setDescription(null);
      setStatus(null);
      setValue(null);
      setStart(null);
      setEnd(null);
      setCategory(null);
      setSKU(null);
      setPrice(null);

      setCRMCustomersSearch([]);
      setCRMLeadsSearch([]);
      setCRMPeopleSearch([]);
      setCRMJobSearch([]);
      setCRMGallerySearch([]);
      setCRMPhotosSearch([]);
      setCRMDocumentSearch([]);
      setCRMContractSearch([]);
      setCRMProposalSearch([]);
      setCRMProductSearch([]);
      setCRMServiceSearch([]);
      setCRMRepresentativeSearch([]);
    }
  };

  const updateSearchALL = () => {
    if (B2B === "B2B") {
      CustomerSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 1,
          search: 1
        }
      })

      LeadSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 0,
          search: 1
        }
      })
    }

    PeopleSearchQuery({
      variables: {
        customer_id,
        count: 0,
        page: 0,
        rows_per_page: 3,
        cell_phone_number: phone,
        name,
        email,
        address,
        representative: searchrepresentative,
        search: 1
      }
    })

    JobSearchQuery({
      variables: {
        customer_id,
        count: 0,
        type,
        name,
        service,
        page: 0,
        rows_per_page: 3,
        representative: searchrepresentative,
        search: 1
      }
    })

    GallerySearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    PhotosSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    DocumentSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ContractSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProposalSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProductSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        price,
        count: 0,
        search: 1
      }
    })

    ServiceSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        count: 0,
        search: 1
      }
    })

    RepresentativeSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        count: 0,
        search: 1
      }
    })
  };

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (CRMCustomers?.length) {
    csvData.push(
      ...CRMCustomers.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: async () => {
        await submitUpdateCRMActivity({
          variables: {
            customer_id,
            ActivityData: {
              ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date"]),
              users_id: user_id,
              activity_type: CRMCustomers?.status === 1 ? "Customer" : "Lead",
              activity_description: CRMCustomers?.status === 1 ? "Updated Customer " + CRMCustomers?.business_name : "Updated Lead " + CRMCustomers?.business_name,
              representative_id: CRMCustomers?.representative_id,
              crm_customers_id: crm_customer_id,
              activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
            }
          }
        });
      }
    }
  );

  const updateTicketDatabase = async () => {
    if (!TicketsubmissionData?.name) {
      alert("Ticket Name is Required");
      return false;
    }

    if (!TicketsubmissionData?.type) {
      alert("Ticket Type is Required");
      return false;
    }

    if (!TicketsubmissionData?.priority) {
      alert("Ticket Priority is Required");
      return false;
    }

    if (!TicketsubmissionData?.requested_completion_date) {
      alert("Ticket Completion Date is Required");
      return false;
    }

    if (TicketsubmissionData) {
      await submitCreateCRMTicket({
        variables: {
          customer_id,
          TicketData: {
            ...omit(TicketsubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name", "count"]),
            owner_id: user_id,
            crm_customers_id: crm_customer_id,
            cc_users: TicketsubmissionData?.cc_users ? TicketsubmissionData?.cc_users?.map((SelectedUser) => { const ReturnedValue = CRMAssignedToUsers?.map((CurrentUser) => { if (SelectedUser === (CurrentUser?.first_name + " " + CurrentUser?.last_name)) { return CurrentUser?.id } })?.filter(CurrentUser => CurrentUser !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            services: TicketsubmissionData?.services ? TicketsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: TicketsubmissionData?.products ? TicketsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            activity_notes: CRMActivitySubmission?.activity_notes
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateTicketFieldHandler = (key) => e => {
    if (key === "representative_id" || key === "assigned_to" || key === "type" ||
      key === "status" || key === "contract_id" || key === "job_id"
      || key === "proposal_id" || key === "photo_gallery_id" ||
      key === "estimate_id" || key === "document_id" ||
      key === "priority" || key === "type") {
      setTicketsubmissionData({
        ...TicketsubmissionData,
        [key]: e
      });
    } else if (key === "file_url") {
      if (TicketsubmissionData?.file_url?.length) {
        setTicketsubmissionData({
          ...TicketsubmissionData,
          [key]: [...TicketsubmissionData.file_url, e.target.value]
        });
      } else {
        setTicketsubmissionData({
          ...TicketsubmissionData,
          [key]: e.target.value
        });
      }
    } else {
      setTicketsubmissionData({
        ...TicketsubmissionData,
        [key]: e.target.value
      });
    }
  };

  const [submitUpdateCRMActivity] = useMutation(
    UPDATE_CRM_ACTIVITY,
    {
      onCompleted: () => {
        LoadNewNotifications();
        if (customer_id !== 38 && customer_id !== 165) {
          if (crm_customer_id && CRMCustomers?.status === 1)
            navigate("/admin/crmcustomerdetail?customer=" + customer_id + "&id=" + crm_customer_id + "&peopleid=" + crm_people_id);
          else if (crm_customer_id && CRMCustomers?.status === 0)
            navigate("/admin/crmleaddetail?customer=" + customer_id + "&id=" + crm_customer_id + "&peopleid=" + crm_people_id);
          else
            navigate("/admin/crmpersondetail?customer=" + customer_id + "&id=" + crm_customer_id + "&peopleid=" + crm_people_id);
        }
      }
    }
  );

  let ShownActivityCount = 0;

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ width: "40%", float: "left" }}>
                  <label
                    style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}
                  ><ConfirmationNumberOutlinedIcon /> {TicketsubmissionData?.name || ""}</label>
                </div>
                <div style={{ width: "40%", float: "left" }}>
                  <input
                    id="search"
                    maxLength={255}
                    placeholder={placeholderText}
                    type="text"
                    autoFocus
                    value={searchstring || ""}
                    onChange={updateSearchHandler()}
                    onClick={() => setplaceholderText("")}
                    onBlur={() => setplaceholderText("   \uf002 Search")}
                    style={{ borderColor: "#b7b7b7", border: "3px #0071CE solid", borderRadius: "100px", height: "50px", width: "300px", fontFamily: 'FontAwesome, Inter', fontSize: "18px", color: "#AAAAAA" }}
                  />
                </div>
              </div>
              {crm_user_level === "crm_admin" && (
                <Tooltip title="Delete Contract" placement="top">
                  <Button style={{ color: Hover === true ? "red" : "#000", float: "right", backgroundColor: "#FFF", marginTop: "13px" }} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      submitDeleteRequest({ skip: !customer_id, variables: { customer_id: customer_id, id: ContractsubmissionData?.id } });
                      navigate("/admin/contracts?customer=" + customer_id);
                    }
                  }}>
                    <FontAwesomeIcon size="lg" icon={Hover ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} />
                  </Button>
                </Tooltip>
              )}
            </CardBody>
          </Card>
          {SearchResults(handleSearchClose, updateSearchALL, setShowAll, ShowAll, setShowCustomers, setShowLeads, setShowPeople, setShowJobs,
            setShowGalleries, setShowPhotos, setShowProducts, setShowServices, setShowContracts, setShowDocuments,
            setShowProposals, setShowReps, CustomerSearchQuery, LeadSearchQuery, PeopleSearchQuery, JobSearchQuery,
            ProductSearchQuery, ServiceSearchQuery, GallerySearchQuery, DocumentSearchQuery, PhotosSearchQuery,
            RepresentativeSearchQuery, ProposalSearchQuery, ContractSearchQuery,
            ShowCustomers, ShowLeads, ShowPeople, ShowJobs, ShowGalleries, ShowPhotos, ShowProducts, ShowServices, ShowContracts,
            ShowDocuments, ShowProposals, ShowReps,
            searchstring, CRMCustomersSearch, CRMLeadsSearch, CRMPeopleSearch, CRMJobSearch, CRMGallerySearch,
            CRMDocumentSearch, CRMContractSearch, CRMProposalSearch, CRMProductSearch, CRMServiceSearch, CRMRepresentativeSearch,
            CRMPhotosSearch, name, value, start, end, status, category, SKU, price, description, phone, address, officephone,
            email, type, service, representative, searchrepresentative,
            setCRMCustomersSearch, setCRMLeadsSearch, setCRMPeopleSearch, setCRMJobSearch, setCRMGallerySearch,
            setCRMPhotosSearch, setCRMDocumentSearch, setCRMContractSearch, setCRMProposalSearch, setCRMProductSearch,
            setCRMServiceSearch, setCRMRepresentativeSearch, Customerloading, Leadsloading, Peopleloading,
            Jobsloading, Galleryloading, Photosloading, Documentsloading, Contractsloading, Proposalsloading, Productsloading,
            Servicesloading, Repsloading, customer_id,
            handleProductOpen, handleServiceOpen, handleRepOpen, OpenPhotoEdit, B2B)}
          <GridContainer marginTop="20px">
            <GridItem xs={12} sm={12} md={4}>
              {crm_customer_id ? (
                <Card profile>
                  <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                    <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmcustomerdetail") === -1 && window.location.href.indexOf("crmleaddetail") === -1 ? <a href={`/admin/crm${CRMCustomers?.status === 1 ? `customer` : `lead`}detail?customer=${customer_id}&id=${crm_customer_id}`} >{CRMCustomers?.status === 1 ? <HomeWorkOutlinedIcon /> : <FlagOutlinedIcon />} View Profile</a> : <div style={{ visibility: "hidden" }}>{CRMCustomers?.status === 1 ? <HomeWorkOutlinedIcon /> : <FlagOutlinedIcon />} View Profile</div>}</div>
                    <CardAvatar profile style={{ display: "inline-flex", alignItems: "right", float: "right !important" }}>
                      <Dropzone
                        path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                        accept={{
                          'image/jpeg': [".jpeg"],
                          'image/png': [".png"]
                        }}
                        onChange={(value) => {
                          updateFieldHandler("profile_image")({ target: { value } });
                          updateDatabase(value);
                        }}
                      >
                        {() => (
                          <Avatar
                            url={CRMCustomers?.profile_image || CustomersDefaultImage}
                            size={130}
                          />
                        )}
                      </Dropzone>
                    </CardAvatar>

                    <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                      onClick={
                        async () => {
                          if (Following > 0) {
                            await submitUpdateCRMFollowingRequest({
                              variables: {
                                customer_id: customer_id,
                                object_type: "Customer",
                                object_id: crm_customer_id,
                                add: 0,
                                id: Following
                              }
                            });
                          } else {
                            await submitUpdateCRMFollowingRequest({
                              variables: {
                                customer_id: customer_id,
                                object_type: "Customer",
                                object_id: crm_customer_id,
                                add: 1,
                              }
                            });
                          }
                        }} >{Following > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                  <CardBody>
                    <h6 className={classes.cardCategory}>
                      Ideal image upload size is 400 x 400
                    </h6>
                    <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                    <label style={{ marginTop: "30px" }}>Business Name</label>
                    <input
                      id="business_name"
                      maxLength={255}
                      type="text"
                      value={CRMCustomers?.business_name || ""}
                      onChange={updateFieldHandler("business_name")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                    <label style={{ marginTop: "30px" }}>Address</label>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Line 1</label>
                      <input
                        id="office_address_line_one"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.office_address_line_one || ""}
                        onChange={updateFieldHandler("office_address_line_one")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Line 2</label>
                      <input
                        id="office_address_line_two"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.office_address_line_two || ""}
                        onChange={updateFieldHandler("office_address_line_two")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>City</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_city"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateCityHandler("office_city", newValue.key);
                          }}
                          value={CRMCustomers?.office_city ? { key: CRMCustomers?.office_city, label: CRMCustomers?.office_city } :
                            { key: "", label: "" }}
                          options={City?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { updateFieldHandler("office_city")(e); }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>State</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_state"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateStateHandler("office_state", newValue.key);
                          }}
                          value={States?.filter((CurrentState) => CurrentState?.name === CRMCustomers?.office_state)?.map((CurrentState) => {
                            return { key: CurrentState?.name, label: CurrentState?.name }
                          })[0] || ""}
                          options={States?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("office_state")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Zip</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_zip"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updatePostalCodeHandler("office_zip", newValue.key);
                          }}
                          value={CRMCustomers?.office_zip ? { key: CRMCustomers?.office_zip, label: CRMCustomers?.office_zip } :
                            { key: "", label: "" }}
                          options={PostalCode?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { updateFieldHandler("office_zip")(e); }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Country</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_country"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateCountryFieldHandler("office_country", newValue.key);
                          }}
                          value={CRMCustomers?.office_country ? { key: CRMCustomers?.office_country, label: CRMCustomers?.office_country } :
                            { key: "", label: "" }}
                          options={Countries?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("office_country")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Phone</label>
                      <input
                        id="office_phone_number"
                        maxLength={255}
                        type="text"
                        value={FormatPhone(CRMCustomers?.office_phone_number) || ""}
                        onChange={updateFieldHandler("office_phone_number")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Website</label>
                      <input
                        id="website"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.website || ""}
                        onChange={updateFieldHandler("website")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Industry</label>
                      <input
                        id="industry"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.industry || ""}
                        onChange={updateFieldHandler("industry")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Revenue</label>
                      <input
                        id="revenue"
                        maxLength={15}
                        type="text"
                        value={revenue === '' && CRMCustomers?.revenue !== undefined && CRMCustomers?.revenue !== '' ? FormatCurrencyWithoutUpdatingState(CRMCustomers?.revenue) : revenue}
                        onBlur={() => {
                          FormatCurrency();
                          if (CRMCustomers?.revenue)
                            updateDatabase();
                        }}
                        onFocus={() => {
                          setCRMCustomers({
                            ...CRMCustomers,
                            ["revenue"]: ''
                          });
                          setrevenue('');
                        }}
                        onChange={(e) => { updateFieldHandler("revenue", e); updateCustomerRevenueValueFieldHandler(e) }}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>SIC Code</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="sic_code"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateSICFieldHandler("sic_code", newValue.key);
                          }}
                          value={SICCodes?.filter((CurrentSICCode) => CurrentSICCode?.sic_code === CRMCustomers?.sic_code)?.map((CurrentSICCode) => {
                            return { key: CurrentSICCode?.sic_code, label: CurrentSICCode?.sic_code + " - " + CurrentSICCode?.description }
                          })[0] || ""}
                          options={SICCodes?.map(({ sic_code, description }) => ({
                            key: sic_code,
                            label: sic_code + " - " + description
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("sic_code")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>NAICS Code</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="naics_code"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateNAICSFieldHandler("naics_code", newValue.key);
                          }}
                          value={NAICSCodes?.filter((CurrentNAICSCode) => CurrentNAICSCode?.naics_code === CRMCustomers?.naics_code)?.map((CurrentNAICSCode) => {
                            return { key: CurrentNAICSCode?.naics_code, label: CurrentNAICSCode?.naics_code + " - " + CurrentNAICSCode?.title }
                          })[0] || ""}
                          options={NAICSCodes?.map(({ naics_code, title }) => ({
                            key: naics_code,
                            label: naics_code + " - " + title
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("naics_code")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Employees</label>
                      <input
                        id="employees"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.employees || ""}
                        onChange={updateFieldHandler("employees")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Established</label>
                      <input
                        id="established"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.established || ""}
                        onChange={updateFieldHandler("established")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>{CRMCustomers?.status === 1 ? "Customer" : "Lead"} Status:</label>
                    <Select
                      selected={CRMCustomers?.customer_status || 0}
                      onSelect={(value) => {
                        updateFieldHandler("customer_status")(value);
                        if (value !== "select") {
                          updateDatabase("", value);
                        }
                      }}
                      options={CustomerStatuses?.map(({ label, value }) => ({
                        key: value,
                        label: label
                      }))}
                      style={{ marginBottom: 20, float: "left", height: "40px" }}
                    />
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <div>
                      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                      <Select
                        options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                          key: id,
                          label: first_name + " " + last_name
                        }))}
                        selected={CRMCustomers?.assigned_to || 0}
                        onSelect={async (value) => {
                          updateFieldHandler("assigned_to")(value);

                          await submitUpdateCRMRequest({
                            variables: {
                              customer_id,
                              BusinessData: {
                                ...omit(CRMCustomers, ["__typename", "update_time", "last_name", "first_name", "status", "revenue", "lead_status", "referrer_name"]),
                                id: crm_customer_id,
                                assigned_to: value,
                                revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
                                status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
                                lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
                              }
                            }
                          });
                          setRepresentative(value);

                          const notify = options => {
                            const key = enqueueSnackbar(options?.text, {
                              variant: options?.variant,
                              persist: true
                            });
                            setTimeout(() => {
                              closeSnackbar(key);
                            }, options?.delay || 2000);
                          }
                          notify({
                            text: "Information has been successfully submitted",
                            variant: "success"
                          });
                        }}
                        style={{ marginBottom: 20, height: "40px", float: "left" }}
                      />
                    </div>
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="referrer_id"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(e, newValue) => {
                          updateReferrerHandler("referrer_id", newValue.key, newValue.label);
                        }}
                        value={CRMCustomers?.referrer_name ? { key: CRMCustomers?.referrer_id, label: CRMCustomers?.referrer_name } :
                          { key: "", label: "" }}
                        options={CRMCustomersReferrer?.map(({ business_name, id }) => ({
                          key: id,
                          label: business_name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("referrer_name")(e); }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                    {CRMCustomers?.referrer_name && !ReferrerFound ?
                      <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                  </CardBody>
                </Card>
              ) : ""}
              {/* PEOPLE */}
              {!crm_customer_id && crm_people_id !== null && (
                CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
                  return (
                    <>
                      <Card profile>
                        <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                          <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmpersondetail") === -1 ? <a href={`/admin/crmpersondetail?customer=${customer_id}&id=${crm_customer_id ? crm_customer_id : `null`}&peopleid=${crm_people_id}`} ><PermIdentityOutlinedIcon /> View Profile</a> : <div style={{ visibility: "hidden" }}><PermIdentityOutlinedIcon /> View Profile</div>}</div>
                          <CardAvatar profile>
                            <Dropzone
                              path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                              accept={{
                                'image/jpeg': [".jpeg"],
                                'image/png': [".png"]
                              }}
                              onChange={(value) => {
                                updatePersonFieldHandler("profile_image")({ target: { value } });
                                updatePersonDatabase(value, "");
                              }}
                              setuploadPercentage={setuploadPercentage}
                            >
                              {() => (
                                <Avatar
                                  url={CurrentPerson?.profile_image || ""}
                                  size={130}
                                />
                              )}
                            </Dropzone>
                          </CardAvatar>
                          <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                            onClick={
                              async () => {
                                if (FollowingPeople > 0) {
                                  await submitUpdateCRMFollowingRequest({
                                    variables: {
                                      customer_id: customer_id,
                                      object_type: "Person",
                                      object_id: crm_people_id,
                                      add: 0,
                                      id: FollowingPeople
                                    }
                                  });
                                } else {
                                  await submitUpdateCRMFollowingRequest({
                                    variables: {
                                      customer_id: customer_id,
                                      object_type: "Person",
                                      object_id: crm_people_id,
                                      add: 1,
                                    }
                                  });
                                }
                              }} >{FollowingPeople > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                        <CardBody>
                          <h6 className={classes.cardCategory}>
                            Ideal image upload size is 400 x 400
                          </h6>
                          <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>First Name</label>
                            <input
                              id="first_name"
                              maxLength={255}
                              type="text"
                              value={PersonsubmissionData?.first_name || CurrentPerson?.first_name || ""}
                              onChange={updatePersonFieldHandler("first_name")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>Last Name</label>
                            <input
                              id="last_name"
                              maxLength={255}
                              type="text"
                              value={PersonsubmissionData?.last_name || CurrentPerson?.last_name || ""}
                              onChange={updatePersonFieldHandler("last_name")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>Email</label>
                            <input
                              id="email"
                              maxLength={255}
                              type="text"
                              value={PersonsubmissionData?.email || CurrentPerson?.email || ""}
                              onChange={updatePersonFieldHandler("email")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>Phone</label>
                            <input
                              id="cell_phone_number"
                              maxLength={255}
                              type="text"
                              value={FormatPhone(PersonsubmissionData?.cell_phone_number) || FormatPhone(CurrentPerson?.cell_phone_number) || ""}
                              onChange={updatePersonFieldHandler("cell_phone_number")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <label style={{ marginTop: "30px" }}>Address</label>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>Line 1</label>
                            <input
                              id="address_line_one"
                              maxLength={255}
                              type="text"
                              value={PersonsubmissionData?.address_line_one || CurrentPerson?.address_line_one || ""}
                              onChange={updatePersonFieldHandler("address_line_one")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "15px", float: "left", width: "22%", textAlign: "left" }}>Line 2</label>
                            <input
                              id="address_line_two"
                              maxLength={255}
                              type="text"
                              value={PersonsubmissionData?.address_line_two || CurrentPerson?.address_line_two || ""}
                              onChange={updatePersonFieldHandler("address_line_two")}
                              onBlur={() => updatePersonDatabase()}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>City</label>
                            <Stack spacing={2}>
                              <Autocomplete
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                disableClearable
                                id="city"
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                onChange={(event, newValue) => {
                                  updatePersonCityHandler("city", newValue.key);
                                }}
                                value={PersonsubmissionData?.city ? { key: PersonsubmissionData?.city, label: PersonsubmissionData?.city } :
                                  { key: "", label: "" }}
                                options={PeopleCity?.map(({ name }) => ({
                                  key: name,
                                  label: name
                                })) || []}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;

                                  return filtered;
                                }}
                                getOptionLabel={(option) => {
                                  if (option === "")
                                    return "";
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { textAlign: "center", width: "100%" }
                                    }}
                                    onChange={e => { updatePersonFieldHandler("city")(e); }}
                                    onBlur={() => updatePersonDatabase()}
                                    maxLength={255}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>State</label>
                            <Stack spacing={2}>
                              <Autocomplete
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                disableClearable
                                id="state"
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                onChange={(event, newValue) => {
                                  updatePersonStateHandler("state", newValue.key);
                                }}
                                value={PersonsubmissionData?.state ? { key: PersonsubmissionData?.state, label: PersonsubmissionData?.state } :
                                  { key: "", label: "" }}
                                options={PeopleStates?.map(({ name }) => ({
                                  key: name,
                                  label: name
                                })) || []}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;

                                  return filtered;
                                }}
                                getOptionLabel={(option) => {
                                  if (option === "")
                                    return "";
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { textAlign: "center", width: "100%" }
                                    }}
                                    onChange={e => { if (e.target.value === "") { updatePersonFieldHandler("state")(e); } }}
                                    onBlur={() => updatePersonDatabase()}
                                    maxLength={255}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Zip</label>
                            <Stack spacing={2}>
                              <Autocomplete
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                disableClearable
                                id="zip_code"
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                onChange={(event, newValue) => {
                                  updatePersonPostalCodeHandler("zip_code", newValue.key);
                                }}
                                value={PersonsubmissionData?.zip_code ? { key: PersonsubmissionData?.zip_code, label: PersonsubmissionData?.zip_code } :
                                  { key: "", label: "" }}
                                options={PeoplePostalCode?.map(({ name }) => ({
                                  key: name,
                                  label: name
                                })) || []}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;

                                  return filtered;
                                }}
                                getOptionLabel={(option) => {
                                  if (option === "")
                                    return "";
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { textAlign: "center", width: "100%" }
                                    }}
                                    onChange={e => { updatePersonFieldHandler("zip_code")(e); }}
                                    onBlur={() => updatePersonDatabase()}
                                    maxLength={255}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Country</label>
                            <Stack spacing={2}>
                              <Autocomplete
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                disableClearable
                                id="country"
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                onChange={(event, newValue) => {
                                  updatePersonCountryFieldHandler("country", newValue.key);
                                }}
                                value={PersonsubmissionData?.country ? { key: PersonsubmissionData?.country, label: PersonsubmissionData?.country } :
                                  { key: "", label: "" }}
                                options={PeopleCountries?.map(({ name }) => ({
                                  key: name,
                                  label: name
                                })) || []}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;

                                  return filtered;
                                }}
                                getOptionLabel={(option) => {
                                  if (option === "")
                                    return "";
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: { textAlign: "center", width: "100%" }
                                    }}
                                    onChange={e => { if (e.target.value === "") { updatePersonFieldHandler("country")(e); } }}
                                    onBlur={() => updatePersonDatabase()}
                                    maxLength={255}
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          {B2B === "B2C" && (
                            <>
                              <div style={{ clear: "both", marginBottom: "20px" }}></div>
                              <label
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "38px", float: "left" }}>Lead Type</label>
                              <Select
                                selected={PersonsubmissionData?.lead_type || CurrentPerson?.lead_type || ""}
                                onSelect={(value) => {
                                  updatePersonFieldHandler("lead_type")({ target: { value } });
                                  updatePersonDatabase("", "", value);
                                }}
                                options={leadTypes?.map(({ label, value }) => ({
                                  key: value,
                                  label: label
                                }))}
                                style={{ marginBottom: 20, float: "left", height: "40px" }}
                              />
                            </>
                          )}
                          <div style={{ clear: "both", marginBottom: "20px" }}></div>
                          <label
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginRight: "7px", float: "left" }}>Lead Status</label>
                          <Select
                            selected={PersonsubmissionData?.lead_status || CurrentPerson?.lead_status || ""}
                            onSelect={(value) => {
                              updatePersonFieldHandler("lead_status")({ target: { value } });
                              updatePersonDatabase("", value);
                            }}
                            options={leadStatuses.map(({ label, value }) => ({
                              key: value,
                              label: label
                            }))}
                            style={{ marginBottom: 20, float: "left", height: "40px" }}
                          />
                          <div style={{ clear: "both", marginBottom: "20px" }}></div>
                          <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                          <Select
                            options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                              key: id,
                              label: first_name + " " + last_name
                            }))}
                            selected={PersonsubmissionData?.assigned_to || 0}
                            onSelect={async (value) => {
                              updatePersonFieldHandler("assigned_to")(value);

                              await submitUpdateCRMPersonRequest({
                                variables: {
                                  customer_id,
                                  customerEmail: {
                                    ...omit(PersonsubmissionData, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text", "referrer_name"]),
                                    cell_phone_number: PersonsubmissionData?.cell_phone_number !== undefined ? PersonsubmissionData?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
                                    office_phone_number: PersonsubmissionData?.office_phone_number !== undefined ? PersonsubmissionData?.office_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
                                    first_name: PersonsubmissionData?.first_name ? PersonsubmissionData?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
                                    id: crm_people_id,
                                    users_id: user_id,
                                    crm_representative_id: representative ? representative : 1,
                                    assigned_to: value
                                  }
                                }
                              });

                              await submitUpdateCRMActivity({
                                variables: {
                                  customer_id,
                                  ActivityData: {
                                    ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date"]),
                                    users_id: user_id,
                                    people_id: crm_people_id,
                                    activity_type: "Person",
                                    activity_description: "Updated Person " + PersonsubmissionData?.first_name + " " + PersonsubmissionData?.last_name,
                                    representative_id: PersonsubmissionData?.representative_id,
                                    crm_customers_id: crm_customer_id ? crm_customer_id : null,
                                    activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                                  }
                                }
                              });

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                            style={{ marginBottom: 20, height: "40px", float: "left" }}
                          />
                          <div style={{ clear: "both", marginBottom: "20px" }}></div>
                          {B2B === "B2C" && (
                            <>
                              <div style={{ clear: "both", marginBottom: "20px" }}></div>
                              <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                              <Stack spacing={2}>
                                <Autocomplete
                                  freeSolo
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disableClearable
                                  id="referrer_id"
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                  onChange={(e, newValue) => {
                                    updatePersonReferrerHandler("referrer_id", newValue.key, newValue.label);
                                  }}
                                  value={PersonsubmissionData?.referrer_name ? { key: PersonsubmissionData?.referrer_id, label: PersonsubmissionData?.referrer_name } :
                                    { key: "", label: "" }}
                                  options={CRMPersonReferrer?.map(({ first_name, last_name, id }) => ({
                                    key: id,
                                    label: first_name + " " + last_name
                                  })) || []}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;

                                    return filtered;
                                  }}
                                  getOptionLabel={(option) => {
                                    if (option === "")
                                      return "";
                                    // Regular option
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { textAlign: "center", width: "100%" }
                                      }}
                                      onChange={e => { updatePersonFieldHandler("referrer_name")(e); }}
                                      onBlur={() => updatePersonDatabase()}
                                      maxLength={255}
                                    />
                                  )}
                                />
                              </Stack>
                              {PersonsubmissionData?.referrer_name && !PersonReferrerFound ?
                                <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                            </>
                          )}
                          <div style={{ clear: "both", marginBottom: "20px" }}></div>
                        </CardBody>
                      </Card>
                    </>
                  )
                })
              )}
              {AllSocials?.length ?
                <>
                  <Card>
                    <CardBody>
                      <label>Social Channels</label>
                      <br />
                      <br />
                      <TableContainer>
                        <Table stickyHeader>
                          <TableRow>
                            {social_columns?.map((datacolumns) => (
                              <TableCell style={{ textAlign: "left", padding: "0px", margin: "0px" }}><label className={classes.selectFormControl}>{datacolumns?.Header}</label></TableCell>
                            ))}
                          </TableRow>
                          {CRMSocials ? Object.keys(CRMSocials)?.map((keyName, index) => (
                            <>
                              <TableRow key={CRMSocials[keyName]?.id}>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <Select
                                    options={AllSocials?.map(({ id, social_channel }) => ({
                                      key: id,
                                      label: social_channel
                                    }))}
                                    selected={CRMSocials[keyName].social_channel_id || 0}
                                    onSelect={updateSocialsocial_channel_id(index, "social_channel_id")}
                                    style={{ marginBottom: 2, height: "40px" }}
                                  />&nbsp;&nbsp;
                                  {CRMSocials[keyName]?.social_channel_url ? <a target="_blank" href={CRMSocials[keyName].social_channel_url}>Go To Link</a> : ""}
                                </TableCell>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <a style={{ cursor: "pointer" }} onClick={RemoveSocial(CRMSocials[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: "5px", margin: "0px", width: "100%" }} colSpan="2">
                                  <CustomInput
                                    id={`customers_top_products${CRMSocials[keyName].id}`}
                                    maxLength="2048"
                                    type="text"
                                    key={`customers_top_products${CRMSocials[keyName].id}`}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: event => { updateSocialssocial_channel_url(index, event) },
                                      defaultValue: CRMSocials[keyName]?.social_channel_url ? CRMSocials[keyName].social_channel_url : ''
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          )) : ""}
                        </Table>
                      </TableContainer>
                      <br />
                      <a style={{ cursor: "pointer" }} onClick={AddSocial}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Social Channel</a>
                      <div style={{ clear: "both", height: "30px" }}></div>
                      <SaveButton
                        color="orange"
                        type="submit"
                        onClick={() => {
                          let ErrorPresent = false;
                          Object.keys(CRMSocials)?.map((keyName, index) => {
                            if (CRMSocials[keyName].social_channel_id === null || CRMSocials[keyName].social_channel_id === '') {
                              alert("Please Select a Social Channel");
                              ErrorPresent = true;
                              return;
                            }
                            else if (CRMSocials[keyName].social_channel_url === null || CRMSocials[keyName].social_channel_url === '') {
                              alert("Please Enter a Social URL");
                              ErrorPresent = true;
                              return;
                            }

                            updateSocials({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                data: omit(CRMSocials[keyName], ["__typename"])
                              }
                            })
                          })

                          if (ErrorPresent === false) {
                            refetchSocial();

                            const notify = options => {
                              const key = enqueueSnackbar(options?.text, {
                                variant: options?.variant,
                                persist: true
                              });
                              setTimeout(() => {
                                closeSnackbar(key);
                              }, options?.delay || 2000);
                            }
                            notify({
                              text: "Information has been successfully submitted",
                              variant: "success"
                            });
                          }
                        }
                        }
                      >
                        Submit
                      </SaveButton>
                    </CardBody>
                  </Card>
                </>
                : ""}
              {/* PEOPLE */}
              {crm_customer_id ? (
                CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>People</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).map((CurrentPerson, index) => {
                        if (index > 4 && showMorePeople === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Name</label>
                                <a href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + CurrentPerson?.crm_customers_id + "&peopleid=" + CurrentPerson?.id} style={{ float: "left" }}>
                                  {CurrentPerson?.first_name} {CurrentPerson?.last_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.cell_phone_number ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Cell</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.cell_phone_number) : ""}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.office_phone_number ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Office</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.office_phone_number) : ""}
                                  &nbsp;&nbsp;&nbsp;{CurrentPerson?.office_extension ? "X   " + CurrentPerson?.office_extension : ""}{CurrentPerson?.office_phone_number ? <br /> : ""}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.email ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Email</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? CurrentPerson?.email : ""}</label>
                              </div> : ""}
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMorePeople) {
                            setshowMorePeople(false);
                          } else {
                            setshowMorePeople(true)
                          }
                        }}>
                          {showMorePeople ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                      <div>
                        <a href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen()
                          }}>+ People</a>
                      </div>
                    </CardBody>
                  </Card>
                )) : ""}
              {/* Onboarding */}
              {CRMActivity?.filter((CurrentActivity) => CurrentActivity?.onboarding_id && CurrentActivity?.people_id === crm_people_id && CurrentActivity?.activity_type === "Onboarding Sent to Customer").length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Onboarding</label>
                    <br />
                    <br />
                    {crm_people_id ? CRMActivity?.filter((CurrentActivity) => CurrentActivity?.onboarding_id && CurrentActivity?.people_id === crm_people_id && CurrentActivity?.activity_type === "Onboarding Sent to Customer")?.map((CurrentActivity, index) => {
                      if (index > 4 && showMoreJobs === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <label style={{ textAlign: "left", width: "40%" }}>Created By:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              {users?.listCustomerUsers?.map(({ id, first_name, last_name }) => {
                                if (CurrentActivity?.users_id === id)
                                  return (<div>{first_name} {last_name}</div>)
                              })}
                            </label>
                            <label style={{ textAlign: "left", width: "40%" }}>Representative:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              {CRMRepresentative?.map(({ id, first_name, last_name }) => {
                                if (CurrentActivity?.representative_id === id)
                                  return (<div>{first_name} {last_name}<br /></div>)
                              })}
                            </label>
                            <label style={{ textAlign: "left", width: "40%" }}>Submitted At:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              {CurrentActivity?.activity_date !== null ? <div>{moment(new Date(parseInt(CurrentActivity?.activity_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</div> : ""}
                            </label>
                            <div style={{ clear: "both" }}></div>
                            <label style={{ textAlign: "left", width: "40%" }}>Sent To:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              {CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === CurrentActivity?.people_id)?.map((CurrentPerson) => {
                                return (CurrentPerson?.first_name + " " + CurrentPerson?.last_name)
                              })}
                            </label>
                            <div style={{ clear: "both", height: "30px" }}></div>
                            <label style={{ textAlign: "left", width: "40%" }}>Onboarding:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              <a href={"/admin/onboardingform?customer=" + customer_id + "&id=" + CurrentActivity?.crm_customers_id + "&peopleid=" + CurrentActivity?.people_id + "&contractid=" + CurrentActivity?.contract_id + (CurrentActivity?.onboarding_id ? "&onboarding_id=" + CurrentActivity?.onboarding_id : "")}
                              >{CurrentActivity?.activity_description}</a>
                            </label>
                            <div style={{ clear: "both", height: "30px" }}></div>
                            <label style={{ textAlign: "left", width: "40%" }}>Contract:</label>
                            <label style={{ width: "60%", textAlign: "left" }}>
                              <a target="_blank" href={CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => { return CurrentContract?.contract_url })}>View Contract</a>
                            </label>
                          </>
                        )
                      }
                    }) : ""}
                    {CRMActivity?.filter((CurrentActivity) => CurrentActivity?.onboarding_id && CurrentActivity?.people_id === crm_people_id && CurrentActivity?.activity_type === "Onboarding Sent to Customer").length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreOnboarding) {
                          setshowMoreOnboarding(false);
                        } else {
                          setshowMoreOnboarding(true)
                        }
                      }}>
                        {showMoreOnboarding ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                  </CardBody>
                </Card>
              )}
              {/* PHOTO GALLERY */}
              {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.id === TicketsubmissionData?.photo_gallery_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Photo Gallery</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.id === TicketsubmissionData?.photo_gallery_id).map((CurrentPhoto, index) => {
                      if (index > 4 && showMorePhotos === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentPhoto?.photo_gallery_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_description ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Description</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentPhoto?.photo_gallery_description}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_last_updated ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentPhoto?.photo_gallery_last_updated))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.id === TicketsubmissionData?.photo_gallery_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMorePhotos) {
                          setshowMorePhotos(false);
                        } else {
                          setshowMorePhotos(true)
                        }
                      }}>
                        {showMorePhotos ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* DOCUMENTS */}
              {CRMDocument?.filter((CurrentDocument) => CurrentDocument?.id === TicketsubmissionData?.document_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Documents</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMDocument?.filter((CurrentDocument) => CurrentDocument?.id === TicketsubmissionData?.document_id).map((CurrentDocument, index) => {
                      if (index > 4 && showMoreDocuments === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentDocument?.documents_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentDocument?.documents_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentDocument?.documents_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentDocument?.documents_update_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentDocument?.documents_update_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMDocument?.filter((CurrentDocument) => CurrentDocument?.id === TicketsubmissionData?.document_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreDocuments) {
                          setshowMoreDocuments(false);
                        } else {
                          setshowMoreDocuments(true)
                        }
                      }}>
                        {showMoreDocuments ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* CONTRACT */}
              {CRMContract?.filter((CurrentContract) => CurrentContract?.id === TicketsubmissionData?.contract_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Contracts</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMContract?.filter((CurrentContract) => CurrentContract?.id === TicketsubmissionData?.contract_id).map((CurrentContract, index) => {
                      if (index > 4 && showMoreContracts === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentContract?.contract_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentContract?.contract_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_value}</label>
                            </div> : ""}
                            {CurrentContract?.contract_start_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Start Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_start_date}</label>
                            </div> : ""}
                            {CurrentContract?.contract_end_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>End Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_end_date}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMContract?.filter((CurrentContract) => CurrentContract?.id === TicketsubmissionData?.contract_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreContracts) {
                          setshowMoreContracts(false);
                        } else {
                          setshowMoreContracts(true)
                        }
                      }}>
                        {showMoreContracts ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* PROPOSAL */}
              {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === TicketsubmissionData?.proposal_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Proposals</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === TicketsubmissionData?.proposal_id).map((CurrentProposal, index) => {
                      if (index > 4 && showMoreProposals === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentProposal?.proposal_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentProposal?.proposal_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_value}</label>
                            </div> : ""}
                            {CurrentProposal?.proposal_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentProposal?.proposal_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === TicketsubmissionData?.proposal_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreProposals) {
                          setshowMoreProposals(false);
                        } else {
                          setshowMoreProposals(true)
                        }
                      }}>
                        {showMoreProposals ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardBody>
                  {/*  Tickets  */}
                  {AddUpdateTicket(updateActivityFieldHandler, updateTicketDatabase, CRMTicketPriorites, CRMTicketTypes,
                    CRMPeople, TicketsubmissionData, CRMAssignedToUsers, updatePeopleFieldHandler, B2B, CRMTicketStatuses,
                    updatePeopleNameFieldHandler, updateTicketFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                    customer_id, true, [], CRMPhotoGallery, CRMContract, CRMProposal, CRMJob, CRMDocument, CRMEstimate,
                    CRMProducts, CRMServices, CRMTicketNotes)}
                </CardBody>
              </Card>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  {LatestActivity(NoteFilterImageSelected, CallFilterImageSelected, EmailFilterImageSelected, TextFilterImageSelected,
                    MeetingFilterImageSelected, TaskFilterImageSelected, DeadlineFilterImageSelected, JobFilterImageSelected, TicketFilterImageSelected,
                    PhotoFilterImageSelected, UploadFilterImageSelected, InboundFormsFilterImageSelected, OnboardingFilterImageSelected, setOnboardingFilterImageSelected,
                    InboundCallsFilterImageSelected, ProposalFilterImageSelected, ContractFilterImageSelected, EstimateFilterImageSelected, setEstimateFilterImageSelected,
                    setNoteFilterImageSelected, setJobFilterImageSelected, setUploadFilterImageSelected, setDeadlineFilterImageSelected,
                    setTaskFilterImageSelected, setMeetingFilterImageSelected, setEmailFilterImageSelected, setTicketFilterImageSelected,
                    setTextFilterImageSelected, setPhotoFilterImageSelected, setContractFilterImageSelected, setProposalFilterImageSelected,
                    setInboundCallsFilterImageSelected, setInboundFormsFilterImageSelected, setCallFilterImageSelected,
                    ShownActivityCount, showMoreActivity, setshowMoreActivity, CRMActivity, users, CRMRepresentative, CRMContract, CRMProposal, CRMDocument,
                    CRMPhotoGallery, CRMJob, customer_id, FormData, CallData, CRMPeople, [], [], CRMEstimate, CRMTickets)}
                  {updatedCustomerEmail && (
                    <Dialog
                      open={Boolean(updatedCustomerEmail)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCustomerProfile
                          updated={updatedCustomerEmail}
                          handleClose={handleClose}
                          setCustomers={setCRMCustomers}
                          customers={CRMCustomers}
                          crm_customer_id={crm_customer_id}
                          source={"Linear"}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                  {updateRep && (
                    <Dialog
                      open={Boolean(updateRep)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateRepresentativeProfile
                          current={updateRep}
                          handleClose={handleRepClose}
                          setCustomers={setCRMCustomers}
                          customers={CRMCustomers}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                  {currentProduct && (
                    <Dialog
                      open={Boolean(currentProduct)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCRMProduct
                          current={currentProduct}
                          setCurrentProduct={setCurrentProduct}
                          handleClose={handleProductClose}
                          refetch={productrefetch}
                          setproduct_cost={setproduct_cost}
                          product_cost={product_cost}
                          product_margin={product_margin}
                          setproduct_margin={setproduct_margin}
                          product_price={product_price}
                          setproduct_price={setproduct_price}
                          product_discount={product_discount}
                          setproduct_discount={setproduct_discount}
                          product_sale_price={product_sale_price}
                          setproduct_sale_price={setproduct_sale_price}
                          product_tax={product_tax}
                          setproduct_tax={setproduct_tax}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                  {currentService && (
                    <Dialog
                      open={Boolean(currentService)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCRMService
                          current={currentService}
                          setCurrentService={setCurrentService}
                          handleClose={handleServiceClose}
                          refetch={servicesrefetch}
                          setservice_cost={setservice_cost}
                          service_cost={service_cost}
                          service_margin={service_margin}
                          setservice_margin={setservice_margin}
                          service_price={service_price}
                          setservice_price={setservice_price}
                          service_discount={service_discount}
                          setservice_discount={setservice_discount}
                          service_sale_price={service_sale_price}
                          setservice_sale_price={setservice_sale_price}
                          service_tax={service_tax}
                          setservice_tax={setservice_tax}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                  {UpdatePhoto?.id && (
                    <Dialog open onClose={() => handleSave()} maxWidth="sm" fullWidth>
                      <DialogContent>
                        <div>
                          <img
                            src={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format`}
                            srcSet={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={UpdatePhoto?.photo_alt_text || ""}
                            loading="lazy"
                            style={{ maxHeight: "200px", margin: "0 auto", display: "block" }}
                          /></div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }} >
                          <label
                            style={{ marginTop: "30px" }}
                          >Photo Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                          <input
                            id="photo_name"
                            maxLength={45}
                            type="text"
                            defaultValue={UpdatePhoto?.photo_name || ""}
                            onChange={updatePhotoFieldHandler("photo_name")}
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }}>
                          <label
                            style={{ marginTop: "30px" }}>Description</label>
                          <textarea
                            id="description"
                            type="text"
                            defaultValue={UpdatePhoto?.photo_description || ""}
                            onChange={updatePhotoFieldHandler("photo_description")}
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ display: "block" }}>
                          <label
                            style={{ marginTop: "30px" }}>Alt Text</label>
                          <textarea
                            id="photo_alt_text"
                            type="text"
                            defaultValue={UpdatePhoto?.photo_alt_text || ""}
                            onChange={updatePhotoFieldHandler("photo_alt_text")}
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                          />
                        </div>
                        <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave() }}>
                          Save
                        </Button>
                      </DialogContent>
                    </Dialog>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

CRMTicketDetail.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMTicketDetail;
