import React, { useContext, useState, useEffect } from "react";

import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import LinearNoContentWrapper from "components/LinearNoContentWrapper";

import People from "./People";
import CRMCustomersPage from "./CRMCustomers";
import CRMLeads from "./CRMLeads";
import Jobs from "./Jobs";
import Documents from "./Documents";
import Contracts from "./Contracts";
import Proposals from "./Proposals";
import Products from "./Products";
import Photos from "./Photos";
import Activity from "./Activity";
import Services from "./Services";
import Tickets from "./Tickets";
import Estimates from "./Estimates";

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Context } from "../../../redux/store";

import SearchResults from "./SearchResults";
import UpdateCRMProduct from "views/Dashboard/UpdateCRMProduct";
import UpdateCRMService from "views/Dashboard/UpdateCRMService";
import UpdateRepresentativeProfile from "views/Dashboard/UpdateRepresentativeProfile";

import {
  LIST_CUSTOMER_EMAILS,
  CRM_CUSTOMERS,
  LIST_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_PHOTOS,
  UPDATE_CRM_PHOTO,
  LIST_SERVICES,
  LIST_PRODUCT
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomerManagement = (props) => {
  const history = useLocation();
  const [name, setSearchName] = React.useState('');
  const [value, setValue] = React.useState('');
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [SKU, setSKU] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [phone, setSearchPhone] = React.useState('');
  const [address, setSearchAddress] = React.useState('');
  const [officephone, setOfficePhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [type, setType] = React.useState('');
  const [service, setService] = React.useState('');
  const [representative, setRepresentative] = React.useState('');
  const [searchrepresentative, setSearchRepresentative] = React.useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMCustomersSearch, setCRMCustomersSearch] = useState(null);
  const [CRMLeadsSearch, setCRMLeadsSearch] = useState(null);
  const [ShowAll, setShowAll] = useState(true);
  const [ShowCustomers, setShowCustomers] = useState(false);
  const [ShowLeads, setShowLeads] = useState(false);
  const [ShowPeople, setShowPeople] = useState(false);
  const [ShowJobs, setShowJobs] = useState(false);
  const [ShowGalleries, setShowGalleries] = useState(false);
  const [ShowPhotos, setShowPhotos] = useState(false);
  const [ShowProducts, setShowProducts] = useState(false);
  const [ShowServices, setShowServices] = useState(false);
  const [ShowContracts, setShowContracts] = useState(false);
  const [ShowDocuments, setShowDocuments] = useState(false);
  const [ShowProposals, setShowProposals] = useState(false);
  const [ShowReps, setShowReps] = useState(false);

  const [CRMPeopleSearch, setCRMPeopleSearch] = useState(null);
  const [CRMJobSearch, setCRMJobSearch] = useState(null);
  const [CRMGallerySearch, setCRMGallerySearch] = useState(null);
  const [CRMPhotosSearch, setCRMPhotosSearch] = useState(null);
  const [CRMDocumentSearch, setCRMDocumentSearch] = useState(null);
  const [CRMContractSearch, setCRMContractSearch] = useState(null);
  const [CRMProposalSearch, setCRMProposalSearch] = useState(null);
  const [CRMProductSearch, setCRMProductSearch] = useState(null);
  const [CRMServiceSearch, setCRMServiceSearch] = useState(null);
  const [CRMRepresentativeSearch, setCRMRepresentativeSearch] = useState(null);
  const [UpdatePhoto, setUpdatePhoto] = useState([]);
  const [searchstring, setsearchstring] = useState(null);
  const [updateRep, setupdateRep] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');
  const [B2B, setB2B] = useState(null);
  const [placeholderText, setplaceholderText] = useState("   \uf002 Search");
  const [state] = useContext(Context);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isShown, setIsShown] = useState(false);
  const customer_id = state.customers?.selected?.id;

  let { data: customer_data, loading: customer_data_loading } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  useEffect(() => {
    setCRMPhotos(Photo_data?.ListPhotos || []);

    setLinearCustomer(budget_data?.getBudget?.monthly_budget);

    customer_data = customer_data?.listCustomers[0];

    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [Photo_data, budget_data, customer_data]);

  let { loading: Customerloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 1,
      search: 1
    },
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });

  const [CustomerSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });


  let { loading: Leadsloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  const [LeadSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  let { loading: Peopleloading } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      cell_phone_number: phone,
      name,
      email,
      address,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  const [PeopleSearchQuery] = useLazyQuery(LIST_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  let { loading: Jobsloading } = useQuery(LIST_JOBS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      value,
      name,
      page: 0,
      rows_per_page: 3,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  const [JobSearchQuery] = useLazyQuery(LIST_JOBS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  let { loading: Galleryloading } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  const [GallerySearchQuery] = useLazyQuery(LIST_PHOTO_GALLERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  let { loading: Photosloading } = useQuery(LIST_PHOTOS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      description,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  const [PhotosSearchQuery] = useLazyQuery(LIST_PHOTOS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  let { loading: Documentsloading } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  const [DocumentSearchQuery] = useLazyQuery(LIST_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  let { loading: Contractsloading } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  const [ContractSearchQuery] = useLazyQuery(LIST_CONTRACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  let { loading: Proposalsloading } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const [ProposalSearchQuery] = useLazyQuery(LIST_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const { refetch: productrefetch, loading: Productsloading } = useQuery(LIST_PRODUCT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      price: !isNaN(+price) ? price : "",
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const [ProductSearchQuery] = useLazyQuery(LIST_PRODUCT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const { refetch: servicesrefetch, loading: Servicesloading } = useQuery(LIST_SERVICES, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  const [ServiceSearchQuery] = useLazyQuery(LIST_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  let { loading: Repsloading } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id || !name,
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      name: name ? name : "",
      search: 1
    },
    onCompleted: (data) => {
      setCRMRepresentativeSearch(data?.listCustomerRepresentatives?.filter((CurrentRep) =>
        CurrentRep?.first_name !== "Not" && CurrentRep?.first_name !== "Assigned")
        || [])
    }
  });

  const [RepresentativeSearchQuery] = useLazyQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMRepresentativeSearch(data?.listCustomerRepresentatives?.filter((CurrentRep) =>
        CurrentRep?.first_name !== "Not" && CurrentRep?.first_name !== "Assigned")
        || [])
    }
  });

  const handleSearchClose = () => () => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
  };

  const handleProductOpen = row => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentProduct(row?.id ? row : {});
  };

  const handleServiceOpen = row => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentService(row?.id ? row : {});
  };

  const handleRepOpen = row => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setupdateRep(row || {});
  };

  const handleRepClose = updated => {
    if (updated) {
      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setupdateRep(null);
  };

  const handleServiceClose = updated => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentService(null);
  };

  const handleProductClose = () => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentProduct(null);
  };

  const OpenPhotoEdit = (id) => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setUpdatePhoto(CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })?.length ? CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })[0] : []);
  };

  const updatePhotoFieldHandler = key => (e) => {
    setUpdatePhoto({
      ...UpdatePhoto,
      [key]: e.target.value
    });
  };

  const handleSave = async () => {
    if (UpdatePhoto?.photo_name === undefined || UpdatePhoto?.photo_name === "") {
      alert("Photo Name is required");
      return false;
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (UpdatePhoto) {
      await submitUpdateCRMPhotoRequest({
        variables: {
          customer_id,
          photo_name: UpdatePhoto?.photo_name,
          photo_description: UpdatePhoto?.photo_description,
          photo_alt_text: UpdatePhoto?.photo_alt_text,
          id: UpdatePhoto?.id
        }
      });
    }

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    return true;
  };

  const [submitUpdateCRMPhotoRequest] = useMutation(
    UPDATE_CRM_PHOTO,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setUpdatePhoto([]);
      }
    }
  );

  const updateSearchHandler = () => e => {
    setsearchstring(e.target.value);

    if (e.target.value.length >= 2) {
      setSearchName(e.target.value);
      setSearchAddress(e.target.value);
      setSearchPhone(e.target.value);
      setEmail(e.target.value);
      setOfficePhone(e.target.value);
      setService(e.target.value);
      setType(e.target.value);
      setDescription(e.target.value);
      setStatus(e.target.value);
      setSearchRepresentative(e.target.value);
      setValue(e.target.value);
      setStart(e.target.value);
      setEnd(e.target.value);
      setCategory(e.target.value);
      setSKU(e.target.value);
      setPrice(e.target.value);
    }
    else {
      setSearchName(null);
      setSearchAddress(null);
      setSearchPhone(null);
      setSearchRepresentative(null);
      setEmail(null);
      setOfficePhone(null);
      setService(null);
      setType(null);
      setDescription(null);
      setStatus(null);
      setValue(null);
      setStart(null);
      setEnd(null);
      setCategory(null);
      setSKU(null);
      setPrice(null);

      setCRMCustomersSearch([]);
      setCRMLeadsSearch([]);
      setCRMPeopleSearch([]);
      setCRMJobSearch([]);
      setCRMGallerySearch([]);
      setCRMPhotosSearch([]);
      setCRMDocumentSearch([]);
      setCRMContractSearch([]);
      setCRMProposalSearch([]);
      setCRMProductSearch([]);
      setCRMServiceSearch([]);
      setCRMRepresentativeSearch([]);
    }
  };

  const updateSearchALL = () => {
    if (B2B === "B2B") {
      CustomerSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 1,
          search: 1
        }
      })

      LeadSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 0,
          search: 1
        }
      })
    }

    PeopleSearchQuery({
      variables: {
        customer_id,
        count: 0,
        page: 0,
        rows_per_page: 3,
        cell_phone_number: phone,
        name,
        email,
        address,
        representative: searchrepresentative,
        search: 1
      }
    })

    JobSearchQuery({
      variables: {
        customer_id,
        count: 0,
        type,
        name,
        service,
        page: 0,
        rows_per_page: 3,
        representative: searchrepresentative,
        search: 1
      }
    })

    GallerySearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    PhotosSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    DocumentSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ContractSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProposalSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProductSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        price,
        count: 0,
        search: 1
      }
    })

    ServiceSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        count: 0,
        search: 1
      }
    })

    RepresentativeSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        count: 0,
        search: 1
      }
    })
  };


  const Delayed = ({ children, waitBeforeShow = 350 }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsShown(true);
      }, waitBeforeShow);
      return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? children : null;
  };

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ width: "40%", float: "left" }}>
                  {history.pathname.indexOf("activity") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Activity List</label>)}
                  {history.pathname.indexOf("crmcustomers") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Customers List</label>)}
                  {history.pathname.indexOf("leads") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Leads List</label>)}
                  {history.pathname.indexOf("people") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>People List</label>)}
                  {history.pathname.indexOf("jobs") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Jobs List</label>)}
                  {history.pathname.indexOf("products") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Products List</label>)}
                  {history.pathname.indexOf("services") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Services List</label>)}
                  {history.pathname.indexOf("photo-gallery") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Photos List</label>)}
                  {history.pathname.indexOf("documents") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Documents List</label>)}
                  {history.pathname.indexOf("estimates") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Estimate List</label>)}
                  {history.pathname.indexOf("contracts") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Contracts List</label>)}
                  {history.pathname.indexOf("proposals") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Proposals List</label>)}
                  {history.pathname.indexOf("tickets") > -1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Tickets List</label>)}
                </div>
                <div style={{ width: "40%", float: "left" }}>
                  <input
                    id="search"
                    placeholder={placeholderText}
                    type="text"
                    value={searchstring || ""}
                    autoFocus
                    onChange={updateSearchHandler()}
                    onClick={() => setplaceholderText("")}
                    onBlur={() => setplaceholderText("   \uf002 Search")}
                    style={{ borderColor: "#b7b7b7", border: "3px #0071CE solid", borderRadius: "100px", height: "50px", width: "300px", fontFamily: 'FontAwesome, Inter', fontSize: "18px", color: "#AAAAAA" }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {SearchResults(handleSearchClose, updateSearchALL, setShowAll, ShowAll, setShowCustomers, setShowLeads, setShowPeople, setShowJobs,
            setShowGalleries, setShowPhotos, setShowProducts, setShowServices, setShowContracts, setShowDocuments,
            setShowProposals, setShowReps, CustomerSearchQuery, LeadSearchQuery, PeopleSearchQuery, JobSearchQuery,
            ProductSearchQuery, ServiceSearchQuery, GallerySearchQuery, DocumentSearchQuery, PhotosSearchQuery,
            RepresentativeSearchQuery, ProposalSearchQuery, ContractSearchQuery,
            ShowCustomers, ShowLeads, ShowPeople, ShowJobs, ShowGalleries, ShowPhotos, ShowProducts, ShowServices, ShowContracts,
            ShowDocuments, ShowProposals, ShowReps,
            searchstring, CRMCustomersSearch, CRMLeadsSearch, CRMPeopleSearch, CRMJobSearch, CRMGallerySearch,
            CRMDocumentSearch, CRMContractSearch, CRMProposalSearch, CRMProductSearch, CRMServiceSearch, CRMRepresentativeSearch,
            CRMPhotosSearch, name, value, start, end, status, category, SKU, price, description, phone, address, officephone,
            email, type, service, representative, searchrepresentative,
            setCRMCustomersSearch, setCRMLeadsSearch, setCRMPeopleSearch, setCRMJobSearch, setCRMGallerySearch,
            setCRMPhotosSearch, setCRMDocumentSearch, setCRMContractSearch, setCRMProposalSearch, setCRMProductSearch,
            setCRMServiceSearch, setCRMRepresentativeSearch, Customerloading, Leadsloading, Peopleloading,
            Jobsloading, Galleryloading, Photosloading, Documentsloading, Contractsloading, Proposalsloading, Productsloading,
            Servicesloading, Repsloading, customer_id,
            handleProductOpen, handleServiceOpen, handleRepOpen, OpenPhotoEdit, B2B)}
          <Delayed>
            <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
              <Toolbar>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <>
                    <Button key={0}>
                      <a style={{ color: history.pathname.indexOf("activity") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/activity?customer=" + customer_id}>Activity</a>
                    </Button>
                    {B2B === "B2B" && (
                      <>
                        <Button key={1}>
                          <a style={{ color: history.pathname.indexOf("leads") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/leads?customer=" + customer_id}>Leads</a>
                        </Button>
                        <Button key={2}>
                          <a style={{ color: history.pathname.indexOf("customers") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/crmcustomers?customer=" + customer_id}>Customers</a>
                        </Button>
                      </>
                    )}
                    <Button key={3}>
                      <a style={{ color: history.pathname.indexOf("people") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/people?customer=" + customer_id}>People</a>
                    </Button>
                    <Button key={4}>
                      <a style={{ color: history.pathname.indexOf("jobs") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/jobs?customer=" + customer_id}>Jobs</a>
                    </Button>
                    <Button key={5}>
                      <a style={{ color: history.pathname.indexOf("products") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/products?customer=" + customer_id}>Products</a>
                    </Button>
                    <Button key={6}>
                      <a style={{ color: history.pathname.indexOf("services") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/services?customer=" + customer_id}>Services</a>
                    </Button>
                    <Button key={7}>
                      <a style={{ color: history.pathname.indexOf("photo-gallery") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/photo-gallery?customer=" + customer_id}>Photos</a>
                    </Button>
                    <Button key={8}>
                      <a style={{ color: history.pathname.indexOf("documents") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/documents?customer=" + customer_id}>Documents</a>
                    </Button>
                    <Button key={9}>
                      <a style={{ color: history.pathname.indexOf("estimates") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/estimates?customer=" + customer_id}>Estimates</a>
                    </Button>
                    <Button key={10}>
                      <a style={{ color: history.pathname.indexOf("proposals") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/proposals?customer=" + customer_id}>Proposals</a>
                    </Button>
                    <Button key={11}>
                      <a style={{ color: history.pathname.indexOf("contracts") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/contracts?customer=" + customer_id}>Contracts</a>
                    </Button>
                    <Button key={12}>
                      <a style={{ color: history.pathname.indexOf("tickets") > -1 ? '#0071CE' : '#909090', marginRight: "8px", marginLeft: "8px" }} href={"/admin/tickets?customer=" + customer_id}>Tickets</a>
                    </Button>
                  </>
                </Box>
              </Toolbar>
            </AppBar>
          </Delayed>
          {history.pathname.indexOf("activity") > -1 && (
            <Activity />
          )}
          {history.pathname.indexOf("leads") > -1 && (
            <CRMLeads />
          )}
          {history.pathname.indexOf("customers") > -1 && (
            <CRMCustomersPage />
          )}
          {history.pathname.indexOf("people") > -1 && (
            <People />
          )}
          {history.pathname.indexOf("jobs") > -1 && (
            <Jobs />
          )}
          {history.pathname.indexOf("products") > -1 && (
            <Products />
          )}
          {history.pathname.indexOf("services") > -1 && (
            <Services />
          )}
          {history.pathname.indexOf("photo-gallery") > -1 && (
            <Photos />
          )}
          {history.pathname.indexOf("documents") > -1 && (
            <Documents />
          )}
          {history.pathname.indexOf("estimates") > -1 && (
            <Estimates />
          )}
          {history.pathname.indexOf("contracts") > -1 && (
            <Contracts />
          )}
          {history.pathname.indexOf("proposals") > -1 && (
            <Proposals />
          )}
          {history.pathname.indexOf("tickets") > -1 && (
            <Tickets />
          )}
          {updateRep && (
            <Card>
              <CardBody>
                <Dialog
                  open={Boolean(updateRep)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogContent>
                    <UpdateRepresentativeProfile
                      current={updateRep}
                      handleClose={handleRepClose}
                      setCustomers={setCRMCustomers}
                      customers={CRMCustomers}
                    />
                  </DialogContent>
                </Dialog>
              </CardBody>
            </Card>
          )}
          {currentProduct && (
            <Card>
              <CardBody>
                <Dialog
                  open={Boolean(currentProduct)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogContent>
                    <UpdateCRMProduct
                      current={currentProduct}
                      setCurrentProduct={setCurrentProduct}
                      handleClose={handleProductClose}
                      refetch={productrefetch}
                      setproduct_cost={setproduct_cost}
                      product_cost={product_cost}
                      product_margin={product_margin}
                      setproduct_margin={setproduct_margin}
                      product_price={product_price}
                      setproduct_price={setproduct_price}
                      product_discount={product_discount}
                      setproduct_discount={setproduct_discount}
                      product_sale_price={product_sale_price}
                      setproduct_sale_price={setproduct_sale_price}
                      product_tax={product_tax}
                      setproduct_tax={setproduct_tax}
                    />
                  </DialogContent>
                </Dialog>
              </CardBody>
            </Card>
          )}
          {currentService && (
            <Card>
              <CardBody>
                <Dialog
                  open={Boolean(currentService)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogContent>
                    <UpdateCRMService
                      current={currentService}
                      setCurrentService={setCurrentService}
                      handleClose={handleServiceClose}
                      refetch={servicesrefetch}
                      setservice_cost={setservice_cost}
                      service_cost={service_cost}
                      service_margin={service_margin}
                      setservice_margin={setservice_margin}
                      service_price={service_price}
                      setservice_price={setservice_price}
                      service_discount={service_discount}
                      setservice_discount={setservice_discount}
                      service_sale_price={service_sale_price}
                      setservice_sale_price={setservice_sale_price}
                      service_tax={service_tax}
                      setservice_tax={setservice_tax}
                    />
                  </DialogContent>
                </Dialog>
              </CardBody>
            </Card>
          )}
          {UpdatePhoto?.id && (
            <Card>
              <CardBody>
                <Dialog open onClose={() => handleSave()} maxWidth="sm" fullWidth>
                  <DialogContent>
                    <div>
                      <img
                        src={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format`}
                        srcSet={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={UpdatePhoto?.photo_alt_text || ""}
                        loading="lazy"
                        style={{ maxHeight: "200px", margin: "0 auto", display: "block" }}
                      /></div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }} >
                      <label
                        style={{ marginTop: "30px" }}
                      >Photo Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                      <input
                        id="photo_name"
                        maxLength={45}
                        type="text"
                        defaultValue={UpdatePhoto?.photo_name || ""}
                        onChange={updatePhotoFieldHandler("photo_name")}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label
                        style={{ marginTop: "30px" }}>Description</label>
                      <textarea
                        id="description"
                        type="text"
                        defaultValue={UpdatePhoto?.photo_description || ""}
                        onChange={updatePhotoFieldHandler("photo_description")}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label
                        style={{ marginTop: "30px" }}>Alt Text</label>
                      <textarea
                        id="photo_alt_text"
                        type="text"
                        defaultValue={UpdatePhoto?.photo_alt_text || ""}
                        onChange={updatePhotoFieldHandler("photo_alt_text")}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                      />
                    </div>
                    <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave() }}>
                      Save
                    </Button>
                  </DialogContent>
                </Dialog>
              </CardBody>
            </Card>

          )}
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
}


CustomerManagement.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CustomerManagement;