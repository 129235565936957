import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useOutletContext } from 'react-router-dom';
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CloseIcon from '@mui/icons-material/Close';
import DeleteButton from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import CSVReader from "components/CSVReader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import UpdateCRMCustomerProfile from "views/Dashboard/UpdateCRMCustomerProfile";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CRM_CUSTOMERS,
  DELETE_CRM_CUSTOMER,
  UPDATE_CRM_ACTIVITY,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES,
  UPLOAD_CRM_CUSTOMERS,
  GET_STAGING_DUPLICATES_CRM_CUSTOMERS,
  COMMIT_CRM_CUSTOMERS,
  UPLOAD_OVERRIDE_CRM_CUSTOMERS,
  DELETE_STAGING_CRM_CUSTOMERS
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForEstimates from "./CheckForEstimates";
import EstimateList from "./EstimateList";
import CheckForTickets from "./CheckForTickets";
import TicketList from "./TicketList";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns, setName, setPhone, setAddress, setRepresentative, crm_user_level, setIndustry, setLead_Status } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }}>
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'center'}
                style={{ verticalAlign: "top" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "" && column.Header !== "Delete" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Business Name" ? setName(event.target.value) :
                        column.Header === "Business Phone" ? setPhone(event.target.value?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", "")) :
                          column.Header === "Address" ? setAddress(event.target.value) :
                            column.Header === "Industry" ? setIndustry(event.target.value) :
                              column.Header === "Lead Status" ? setLead_Status(event.target.value) :
                                setRepresentative(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
        })}
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

const CRMCustomersPage = (props) => {
  const [state] = useContext(Context);
  const [DuplicatCRMCustomers, setDuplicatCRMCustomers] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [TimeoutConst, setTimeoutConst] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('update_time');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [Lead_Status, setLead_Status] = React.useState('');
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMEstimate, setCRMEstimate] = useState([]);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [Hover, setHover] = useState(null);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [addedCount, setAddedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [ShowDuplicateFile, setShowDuplicateFile] = useState(null);
  const [ShowOverrideLoading, setShowOverrideLoading] = useState(false);
  const [CheckingForDuplicates, setCheckingForDuplicates] = useState(false);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.readMe?.crm_user_level;
  const { LoadNewNotifications } = useOutletContext();

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  const [submitUpdateCRMActivity] = useMutation(UPDATE_CRM_ACTIVITY,
    {
      onCompleted: () => {
        LoadNewNotifications();
      }
    });

  let { data: Activity_data } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative,
      name,
      phone,
      industry,
      lead_status: Lead_Status,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      status: 1
    }
  });


  let { data: all_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1,
      status: 1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: people_data, refetch: refetchpeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const CRMActivitydata = Activity_data?.ListActivity || [];

  const CRMProposaldata = Proposal_data?.ListProposal || [];

  const CRMContractdata = Contract_data?.ListContract || [];

  const CRMJobdata = Job_data?.ListJobs || [];

  const CRMPhotoGallerydata = Photo_Gallery_data?.ListPhotoGallery || [];

  const CRMPhotosdata = Photo_data?.ListPhotos || [];

  const CRMRepresentativedata = Representative_data?.listCustomerRepresentatives || [];

  const CRMDocumentdata = document_data?.ListDocuments || [];

  const CustomerPeopledata = people_data?.listCustomerEmails || [];

  all_data = all_data?.CRMCustomers || [];

  useEffect(() => {
    setCRMCustomers(data?.CRMCustomers);
    setCRMProposal(CRMProposaldata);
    setCRMContract(CRMContractdata);
    setCRMJob(CRMJobdata);
    setCRMPhotoGallery(CRMPhotoGallerydata);
    setCRMPhotos(CRMPhotosdata);
    setCRMPeople(CustomerPeopledata);
    setCRMDocument(CRMDocumentdata);
    setCRMRepresentative(CRMRepresentativedata);
    setCRMServices(service_data?.ListServices || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);
  }, [data, CRMActivitydata, CRMRepresentativedata, Estimate_data, all_ticket_data,
    CRMProposaldata, CRMContractdata, CRMJobdata, CRMPhotoGallerydata, CRMRepresentativedata,
    CRMDocumentdata, CustomerPeopledata, CRMPhotosdata]);

  const classes = useStyles();

  const [uploadCRMCustomers] = useMutation(
    UPLOAD_CRM_CUSTOMERS);

  const [uploadOverrideCRMCustomers] = useMutation(
    UPLOAD_OVERRIDE_CRM_CUSTOMERS);

  const [deleteStagingCRMCustomers] = useMutation(
    DELETE_STAGING_CRM_CUSTOMERS
  );

  const [getStagingDuplicates] = useLazyQuery(GET_STAGING_DUPLICATES_CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStagingDuplicatesCRMCustomers }) => {
      setDuplicatCRMCustomers(getStagingDuplicatesCRMCustomers);
      if (getStagingDuplicatesCRMCustomers?.length > 0) {
        setShowDuplicateFile(true);
      }

      setCheckingForDuplicates(false);
    }
  });

  const [commitCustomerEmails] = useMutation(
    COMMIT_CRM_CUSTOMERS,
    {
      onCompleted: () => {
        setUploadedCount(0);
        setAddedCount(0);
        refetch();
        refetchpeople();
      }
    }
  );

  const onCSVLoaded = async (CRMCustomersdata) => {
    CRMCustomersdata = CRMCustomersdata?.filter(CurrentCustomer =>
      CurrentCustomer?.business_name !== '')

    setTotalCount(CRMCustomersdata.length);
    setCsvUploadOpen(true);
    const size = 100;
    const upload = async (start) => {
      if (start >= CRMCustomersdata.length) {
        setCsvUploadOpen(false);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        return;
      }
      await uploadCRMCustomers({
        fetchPolicy: "no-cache",
        variables: {
          customer_id,
          CRMcustomers: CRMCustomersdata.slice(start, start + size),
          leadsUpload: 0
        }
      }).then(async ({ data: { uploadCRMCustomers: { count, inserted } } }) => {
        setUploadedCount(u => u + count);
        setAddedCount(a => a + inserted);
        await upload(start + size);
      });
    };

    // delete rows from the hydrate_customer_list_ table
    await deleteStagingCRMCustomers({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    await upload(0);

    await getStagingDuplicates({
      variables: {
        customer_id
      }
    })

    // now select staging data and compare to hydrate customer list
    // if record exists leave it in stage for duplicate csv
    // if the key is not found insert into hydrate customer list
    // and remove it from staging
    await commitCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
        leadsUpload: 0
      }
    });
  };

  const csv_columns = compact([
    {
      Header: "Business Name",
      accessor: "business_name",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        FormatPhone(original?.office_phone_number)
    },
    {
      Header: "Business Address Line 1",
      accessor: "office_address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business Address Line 2",
      accessor: "office_address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business City",
      accessor: "office_city",
      optional: true
    },
    {
      Header: "Business Zip Code",
      accessor: "office_zip",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business State",
      accessor: "office_state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Country",
      accessor: "office_country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Industry",
      accessor: "industry",
      optional: true
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
      optional: true
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
      optional: true
    },
    {
      Header: "Established",
      accessor: "established",
      optional: true
    },
    {
      Header: "CRM Customer ID",
      accessor: "id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.id)
    },
    {
      Header: "Representative ID",
      accessor: "representative_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.representative_id)
    }
  ]);

  const inputs = [
    {
      field: "business_name",
      label: "Business Name",
      optional: true
    },
    {
      field: "office_phone_number",
      label: "Business Phone",
      optional: true
    },
    {
      field: "office_address_line_one",
      label: "Business Address Line 1",
      optional: true
    },
    {
      field: "office_address_line_two",
      label: "Business Address Line 2",
      optional: true
    },
    {
      field: "office_city",
      label: "Business City",
      optional: true
    },
    {
      field: "office_state",
      label: "Business State",
      optional: true
    },
    {
      field: "office_zip",
      label: "Business Zip Code",
      optional: true
    },
    {
      field: "office_country",
      label: "Business Country",
      optional: true
    },
    {
      field: "website",
      label: "Website",
      optional: true
    },
    {
      field: "status",
      label: "Status",
      optional: true
    },
    {
      label: "Industry",
      field: "industry",
      optional: true
    },
    {
      label: "Revenue",
      field: "revenue",
      optional: true
    },
    {
      label: "SIC Code",
      field: "sic_code",
      optional: true
    },
    {
      label: "NAICS Code",
      field: "naics_code",
      optional: true
    },
    {
      label: "Employees",
      field: "employees",
      optional: true
    },
    {
      label: "Established",
      field: "established",
      optional: true
    },
    {
      label: "Business Facebook",
      field: "Business_Facebook",
      optional: true
    },
    {
      label: "Business LinkedIn",
      field: "Business_LinkedIn",
      optional: true
    },
    {
      label: "Business Twitter",
      field: "Business_Twitter",
      optional: true
    },
    {
      label: "Business Instagram",
      field: "Business_Instagram",
      optional: true
    },
    {
      label: "Business Pinterest",
      field: "Business_Pinterest",
      optional: true
    },
    {
      label: "Business Mastodon",
      field: "Business_Mastodon",
      optional: true
    },
    {
      label: "Business Telegram",
      field: "Business_Telegram",
      optional: true
    },
    {
      label: "Business Tumblr",
      field: "Business_Tumblr",
      optional: true
    },
    {
      label: "Business Subreddit",
      field: "Business_Subreddit",
      optional: true
    },
    {
      label: "Google Business Profile",
      field: "Google_Business_Profile",
      optional: true
    },
    {
      label: "Business YouTube",
      field: "Business_YouTube",
      optional: true
    },
    {
      label: "Business Tiktok",
      field: "Business_Tiktok",
      optional: true
    }
  ];

  const CSVdownloadcolumns = [
    {
      accessor: "id",
      Header: "Business ID",
    },
    {
      accessor: "business_name",
      Header: "Business Name",
    },
    {
      accessor: "office_phone_number",
      Header: "Business Phone",
    },
    {
      accessor: "office_address_line_one",
      Header: "Business Address Line 1",
    },
    {
      accessor: "office_address_line_two",
      Header: "Business Address Line 2",
    },
    {
      accessor: "office_city",
      Header: "Business City",
    },
    {
      accessor: "office_state",
      Header: "Business State",
    },
    {
      accessor: "office_zip",
      Header: "Business Zip Code",
    },
    {
      accessor: "office_country",
      Header: "Business Country",
    },
    {
      accessor: "website",
      Header: "Website",
    },
    {
      accessor: "status",
      Header: "status",
    },
    {
      Header: "Industry",
      accessor: "industry",
    },
    {
      Header: "Revenue",
      accessor: "revenue",
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
    },
    {
      Header: "Established",
      accessor: "established",
    },
    {
      Header: "CRM Customer ID",
      accessor: "id",
    },
    {
      Header: "Representative ID",
      accessor: "representative_id",
    }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: async () => {
        refetch();
        LoadNewNotifications
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CRM_CUSTOMER,
    {
      onCompleted: () => {
        refetch();

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = async (BusinessData) => {
    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (BusinessData) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(BusinessData, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status"]),
            office_phone_number: `${BusinessData?.office_phone_number !== undefined ? BusinessData?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: BusinessData?.revenue ? BusinessData?.revenue?.replace(/[^0-9.]+/g, "") : "",
            status: BusinessData?.status === undefined ? "1" : `${BusinessData?.status}`,
            lead_status: BusinessData?.lead_status && typeof BusinessData?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === BusinessData?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : BusinessData?.lead_status ? BusinessData?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  const columns = compact([
    {
      Header: "Business Name",
      accessor: "business_name"
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number"
    },
    {
      Header: "Address",
      accessor: "office_address_line_one"
    },
    {
      Header: "Industry",
      accessor: "industry"
    },
    {
      Header: "Lead Status",
      accessor: "lead_status"
    },
    {
      Header: "Representative",
      accessor: "representative_id"
    },
    {
      Header: "Delete",
    }
  ]);

  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data, handleOpen } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell style={{ padding: "0px", margin: "0px" }}>
                {(CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand) ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand) ||
                  CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand) ||
                  CheckForEstimates(row, index, "crm_customers_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand) ||
                  CheckForTickets(row, index, "crm_customers_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell style={{ padding: "0px", margin: "0px" }}>
                <>
                  <a
                    href={"/admin/crmcustomerdetail?customer=" + customer_id + "&id=" + row?.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row?.business_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px" }}><div style={{ width: "200px" }}>{FormatPhone(row?.office_phone_number)}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px" }}><div>{row?.office_address_line_one} {row?.office_address_line_two} {row?.office_city} {row?.office_state} {row?.office_zip} {row?.office_country}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px" }}><div style={{ width: "200px" }}>{row?.industry}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px" }}><div style={{ width: "200px" }}>{row?.lead_status}</div>
              </TableCell>
              <TableCell align="center" style={{ padding: "0px", margin: "0px" }}><div>{CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === row?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
              </TableCell>
              <TableCell style={{ padding: "0px", margin: "0px" }}>{crm_user_level === "crm_admin" && (
                <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("Are you sure?")) {
                    submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                  }
                }}>
                  <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { var delay=2000;setTimeoutConst(setTimeout(function(){setHover(row?.id)}, delay)); }} onMouseLeave={() => {setHover(null);clearTimeout(TimeoutConst);}} />
                </DeleteButton>
              )}</TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={100}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <tbody>
                      {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                      {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                      {CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "crm_customers_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "crm_customers_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "crm_customers_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForEstimates(row, index, "crm_customers_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                      {EstimateList(row, index, CRMEstimate, "crm_customers_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "crm_customers_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "crm_customers_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                      {CheckForTickets(row, index, "crm_customers_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                      {TicketList(row, index, CRMTickets, "crm_customers_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                    </tbody>
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = loading || deleting || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <HomeWorkOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
              >
                Add Customer
              </Button>
              <CSVDownloader
                data={CRMCustomers || []}
                columns={CSVdownloadcolumns}
                filename={`LinearCRM Customers (${state.customers?.selected?.name})`}
              />
              {crm_user_level === "crm_admin" && (
                <CSVReader
                  onLoaded={onCSVLoaded}
                  disabled={isLoading}
                  inputs={inputs}
                />
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table
                stickyHeader
                aria-label="collapsible table"
                columns={columns}
                data={data}
                key="CustomerTable1" >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setPhone={setPhone}
                  setAddress={setAddress}
                  setIndustry={setIndustry}
                  setLead_Status={setLead_Status}
                  setRepresentative={setRepresentative}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={CRMCustomers} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCRMCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    customers={CRMCustomers}
                    type={"add"}
                  />
                </DialogContent>
              </Dialog>
            )}
            {ShowDuplicateFile && (
              <Dialog
                open={Boolean(ShowDuplicateFile)}
              >
                <DialogTitle>
                  See Duplicate Customers
                  <div style={{ float: "right" }}><Button onClick={() => setShowDuplicateFile(false)}><CloseIcon /></Button></div>
                </DialogTitle>
                <DialogContent align={'center'}>
                  Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
                  <br />
                  <br />
                  Click here to overrides the duplicates.  This will overwrite<br />your current data for Customers
                  <br />
                  <br />
                  <CSVDownloader
                    incomingstyle={{ float: "left" }}
                    data={DuplicatCRMCustomers || []}
                    columns={csv_columns}
                    filename={`LinearCRM Customer Duplicates (${state.customers?.selected?.name})`}
                    text={"Download"}
                  />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                    await uploadOverrideCRMCustomers({
                      fetchPolicy: "no-cache",
                      variables: {
                        customer_id,
                        leadsUpload: 0
                      }
                    })

                    setShowDuplicateFile(false);

                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}>Override</Button>
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
        {csvUploadOpen && (
          <Dialog
            open={Boolean(csvUploadOpen)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Upload Customers
            </DialogTitle>
            <DialogContent>
              {uploadedCount} / {totalCount} uploaded <br />
              {addedCount} added <br />
            </DialogContent>
          </Dialog>
        )}
        {CheckingForDuplicates && (
          <Dialog
            open={Boolean(CheckingForDuplicates)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent align={'center'}>
              <h4>We're Importing your data and checking for Duplicates.  Please stay on this page.</h4><br /><br />
              <CircularProgress />
            </DialogContent>
          </Dialog>
        )}
        {ShowDuplicateFile && (
          <Dialog
            open={Boolean(ShowDuplicateFile)}
          >
            <DialogTitle>
              See Duplicate People
              <div style={{ float: "right" }}><Button onClick={() => setShowDuplicateFile(false)}><CloseIcon /></Button></div>
            </DialogTitle>
            <DialogContent align={'center'}>
              Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
              <br />
              <br />
              Click here to overrides the duplicates.  This will overwrite<br />your current data for Customers
              <br />
              <br />
              <CSVDownloader
                incomingstyle={{ float: "left" }}
                data={DuplicatCRMCustomers || []}
                columns={csv_columns}
                filename={`LinearCRM Customer Duplicates (${state.customers?.selected?.name})`}
                text={"Download"}
              />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                setShowOverrideLoading(true);

                await uploadOverrideCRMCustomers({
                  fetchPolicy: "no-cache",
                  variables: {
                    customer_id
                  }
                })

                setShowDuplicateFile(false);
                setShowOverrideLoading(true);
                refetch();
                refetchpeople();

                const notify = options => {
                  const key = enqueueSnackbar(options?.text, {
                    variant: options?.variant,
                    persist: true
                  });
                  setTimeout(() => {
                    closeSnackbar(key);
                  }, options?.delay || 2000);
                }
                notify({
                  text: "Information has been successfully submitted",
                  variant: "success"
                });
              }}>Override</Button>
              {ShowOverrideLoading && (
                <>
                  <br />
                  <br />
                  Overriding...
                  <br />
                  < CircularProgress />
                </>
              )}
            </DialogContent>
          </Dialog>
        )}
      </GridItem>
    </GridContainer>
  );
};

CRMCustomersPage.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMCustomersPage;
