import React from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import ImagesPlugin from '../plugins/ImagesPlugin/index.tsx';
import DragDropPaste from '../plugins/ImagesPlugin/DragDropPastePlugin.tsx'
import { ImageNode } from '../plugins/ImagesPlugin/ImageNode.tsx';

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "../plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "../plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "../plugins/AutoLinkPlugin";
import "assets/css/richtext_styles.css";
import RichTextTheme from "assets/themes/RichTextTheme";

function Placeholder({field = null, holder = -1}) {
  if (field.field === "product_description")
    return <div className="editor-placeholder">Enter Product Description...</div>;
  else if (field.field === "service_description")
    return <div className="editor-placeholder">Enter Service Description...</div>;
  else if (holder === 0)
    return <div className="editor-placeholder">Give more detailed information here...</div>;
  else if (holder > 0)
    return <div className="editor-placeholder">Reply...</div>;
  else
    return <div className="editor-placeholder">Enter Notes...</div>;
}

const RichTextEditor = ({ updateFieldHandler, field, current, readonly, holder = null, style = null }) => {
  let editorConfig = {
    editorState: current && current[field] ? current[field] : '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
    // The editor theme
    theme: RichTextTheme,
    editable: readonly === true ? false : true,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode
    ]
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        {readonly === false && (
          <ToolbarPlugin />
        )}
        <div className="editor-inner">
          {readonly === false ?
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" style={style} />}
              placeholder={<Placeholder field={field} holder={holder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            :
            <div>
              {field !== "notes" ? <b>Note:</b> : <b>Comment:</b>} <RichTextPlugin
                contentEditable={<ContentEditable style={style} />}
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
          }
          {readonly === false && (
            <OnChangePlugin onChange={(editorState) => { updateFieldHandler(field)(JSON.stringify(editorState.toJSON())) }} />
          )}
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ImagesPlugin />
          <DragDropPaste />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
}

export default RichTextEditor;