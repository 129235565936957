import React from "react";

import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Dropzone from "components/Dropzone";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from '@mui/icons-material/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire, faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons'

import "assets/css/richtext_styles.css";

const filter = createFilterOptions();

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}

const AddUpdatePhotoGallery = (updateActivityFieldHandler, updateGalleryDatabase,
  CRMPeople, updateGalleryFieldHandler, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, GallerysubmissionData, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, CRMPhotos, refetchPhotos, submitDeletePhotoRequest, Update = false, CRMActivity = [],
  CRMJob, CRMProposal, CRMContract, PhotoHover, setPhotoPhotoHover, UpdatePhoto, setUpdatePhoto, handleSave,
  updatePhotoFieldHandler, OpenPhotoEdit) => {
  var JSZip = require("jszip");
  let zip = new JSZip();
  var FileSaver = require('file-saver');
  let tempFolder = zip.folder("temp");

  if (CRMPhotos?.filter((CurrentPhoto) =>
    CurrentPhoto?.photo_gallery_id === GallerysubmissionData?.id)?.length) {
    CRMPhotos?.map((CurrentPhoto, index) => {
      if (CurrentPhoto?.photo_gallery_id === GallerysubmissionData?.id) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function async() {
          tempFolder.file(CurrentPhoto?.photo_url?.substring(CurrentPhoto?.photo_url?.lastIndexOf("/") + index), xhr.response);
        };
        xhr.open('GET', CurrentPhoto?.photo_url);
        xhr.send();
      }
    })
  }

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Upload"} Photos</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", whiteSpace: "nowrap", width: "140px" }}>Gallery Name:</label>
        <input
          id="photo_gallery_name"
          maxLength={255}
          type="text"
          value={GallerysubmissionData?.photo_gallery_name || ""}
          onChange={updateGalleryFieldHandler("photo_gallery_name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", whiteSpace: "nowrap", width: "140px" }}>Gallery Descrption:</label>
        <input
          id="photo_gallery_description"
          maxLength={255}
          type="text"
          value={GallerysubmissionData?.photo_gallery_description || ""}
          onChange={updateGalleryFieldHandler("photo_gallery_description")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "40px" }}></div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <a
          style={{ color: "#0071CE", cursor: "pointer" }}
          onClick={async () => {
            setTimeout(() => {
              tempFolder.generateAsync({ type: "blob", compression: "DEFLATE" }).then(function (content) {
                FileSaver.saveAs(content, GallerysubmissionData?.photo_gallery_name);
              });
            }, 150 * CRMPhotos?.filter((CurrentPhoto, index) =>
              CurrentPhoto?.photo_gallery_id === GallerysubmissionData?.id)?.length);
          }
          }>Download Gallery&nbsp;<FontAwesomeIcon
            style={{ color: '#0071CE', marginRight: "15px", marginLeft: "15px", cursor: "pointer" }}
            size="lg"
            icon={faArrowDownToLine} /></a>
      </div>
      <div style={{ clear: "both", marginTop: "40px" }}></div>
      <ImageList>
        {CRMPhotos?.map((CurrentPhoto, index) => {
          if (CurrentPhoto?.photo_gallery_id === GallerysubmissionData?.id)
            return (
              <ImageListItem key={CurrentPhoto?.id}>
                <img
                  src={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format`}
                  srcSet={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={CurrentPhoto?.photo_alt_text || ""}
                  loading="lazy"
                  style={{ maxHeight: "200px", objectFit: 'contain', backgroundColor: "#363636" }}
                />
                <ImageListItemBar
                  title={CurrentPhoto?.photo_name}
                  subtitle={CurrentPhoto?.photo_description}
                  actionIcon={
                    <>
                      <FontAwesomeIcon
                        style={{ color: '#fff', marginRight: "15px", marginLeft: "15px", cursor: "pointer" }}
                        size="lg"
                        icon={faArrowDownToLine}
                        onClick={() => {
                          var xhr = new XMLHttpRequest();
                          xhr.responseType = 'blob';
                          xhr.onload = function () {
                            var a = document.createElement('a');
                            a.href = window.URL.createObjectURL(xhr.response);
                            a.download = CurrentPhoto?.photo_url?.substring(CurrentPhoto?.photo_url?.lastIndexOf("/") + 1);
                            a.style.display = 'none';
                            document.body.appendChild(a);
                            a.click();
                            a.remove()
                          };
                          xhr.open('GET', CurrentPhoto?.photo_url);
                          xhr.send();
                        }}
                      />
                      <EditIcon
                        sx={{ color: '#fff', cursor: "pointer" }}
                        aria-label={`info about ${CurrentPhoto?.photo_name}`}
                        onClick={() => {
                          OpenPhotoEdit(CurrentPhoto?.id)
                        }}
                      >
                        <InfoIcon />
                      </EditIcon>
                      <FontAwesomeIcon
                        style={{ color: '#fff', marginRight: "15px", marginLeft: "15px", cursor: "pointer" }}
                        size="lg"
                        icon={PhotoHover[index] ? faDumpsterFire : faDumpster}
                        onMouseOver={() => { setPhotoPhotoHover({ ...PhotoHover, [index]: true }) }}
                        onMouseLeave={() => { setPhotoPhotoHover({ ...PhotoHover, [index]: false }) }}
                        onClick={() => {
                          if (window.confirm("Are you sure?")) {
                            submitDeletePhotoRequest({ skip: !customer_id, variables: { customer_id: customer_id, id: CurrentPhoto?.id } });
                          }
                        }} />
                    </>
                  }
                />
              </ImageListItem>
            )
        }
        )}
      </ImageList>
      {uploadPercentage && (
        <LinearDeterminate uploadPercentage={uploadPercentage} />
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px" }}>
        <Dropzone
          setuploadPercentage={setuploadPercentage}
          path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
          onChange={(value) => { updateGalleryFieldHandler("photo_gallery_url")({ target: { value } }) }}
          value={GallerysubmissionData["photo_gallery_url"] || ""}
          multiple={true}
          maxFiles={100}
          accept={{
            'image/jpeg': [".jpeg"],
            'image/png': [".png"],
            'image/jpg': [".jpg"],
            'image/tiff': [".tiff"],
            'image/ai': [".ai"],
            'image/gif': [".gif"],
            'image/eps': [".eps"],
            'image/psd': [".psd"],
            'image/raw': [".raw"],
            'image/idd': [".idd"]
          }}
        >
          {() => {
            return (
              <div style={{ width: "100%", height: "300px", borderColor: "grey", borderWidth: "1px", borderStyle: "solid", textAlign: "center", verticalAlign: "middle", lineHeight: "300px", borderRadius: "5px" }}>
                <Button
                  style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                >
                  Upload file(s)
                </Button>
                <span> ...or drag image file(s) here.  You must save to upload photos.</span>
              </div>
            )
          }}</Dropzone>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {window.location.href.indexOf("crmpersondetail") === -1 && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", float: "left", width: "115px" }}>People:</label>
            {Update === true ?
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === GallerysubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: GallerysubmissionData?.people_name ? GallerysubmissionData?.people_name : "" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Photo")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Photo")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Photo")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
              :
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Photo")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Photo")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Photo")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
            }
            {PersonFound ? "" :
              <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
          </div>
        </>
      )}
      {CRMJob?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Job:</label>
          <Select
            options={CRMJob?.map(({ id, job_name }) => ({
              key: id,
              label: job_name
            }))}
            selected={GallerysubmissionData?.job_id || 0}
            onSelect={updateGalleryFieldHandler("job_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMContract?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Contract:</label>
          <Select
            options={CRMContract?.map(({ id, contract_name }) => ({
              key: id,
              label: contract_name
            }))}
            selected={GallerysubmissionData?.contract_id || 0}
            onSelect={updateGalleryFieldHandler("contract_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMProposal?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Proposal:</label>
          <Select
            options={CRMProposal?.map(({ id, proposal_name }) => ({
              key: id,
              label: proposal_name
            }))}
            selected={GallerysubmissionData?.proposal_id || 0}
            onSelect={updateGalleryFieldHandler("proposal_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Assign To:</label>
      <Select
        options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
          key: id,
          label: first_name + " " + last_name
        }))}
        selected={GallerysubmissionData?.assigned_to || 0}
        onSelect={updateGalleryFieldHandler("assigned_to")}
        style={{ marginBottom: 20, float: "left", height: "40px" }}
      />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.photo_gallery_id === GallerysubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.photo_gallery_id === GallerysubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.photo_gallery_id === GallerysubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.photo_gallery_id === GallerysubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px" }} /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateGalleryDatabase();
      }}>
        Save
      </Button>
      {UpdatePhoto?.id && (
        <Dialog open onClose={() => handleSave()} style={{ minWidth: "40%" }}>
          <DialogContent>
            <div>
              <img
                src={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format`}
                srcSet={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={UpdatePhoto?.photo_alt_text || ""}
                loading="lazy"
                style={{ maxHeight: "200px", margin: "0 auto", display: "block" }}
              /></div>
            <div style={{ clear: "both" }}></div>
            <div style={{ display: "block" }} >
              <label
                style={{ marginTop: "30px" }}
              >Photo Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
              <input
                id="photo_name"
                maxLength={45}
                type="text"
                defaultValue={UpdatePhoto?.photo_name || ""}
                onChange={updatePhotoFieldHandler("photo_name")}
                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
              />
            </div>
            <div style={{ clear: "both" }}></div>
            <div style={{ display: "block" }}>
              <label
                style={{ marginTop: "30px" }}>Description</label>
              <textarea
                id="description"
                type="text"
                defaultValue={UpdatePhoto?.photo_description || ""}
                onChange={updatePhotoFieldHandler("photo_description")}
                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
              />
            </div>
            <div style={{ clear: "both" }}></div>
            <div style={{ display: "block" }}>
              <label
                style={{ marginTop: "30px" }}>Alt Text</label>
              <textarea
                id="photo_alt_text"
                type="text"
                defaultValue={UpdatePhoto?.photo_alt_text || ""}
                onChange={updatePhotoFieldHandler("photo_alt_text")}
                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
              />
            </div>
            <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave() }}>
              Save
            </Button>
          </DialogContent>
        </Dialog>
      )
      }
    </div >
  )
};

export default AddUpdatePhotoGallery;
