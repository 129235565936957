import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteButton from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import CheckForEstimates from "./CheckForEstimates";
import EstimateList from "./EstimateList";
import CheckForTickets from "./CheckForTickets";
import TicketList from "./TicketList";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  DELETE_DOCUMENT,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setStatus, setRepresentative, crm_user_level } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'center'}
                style={{ verticalAlign: "top" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Date" && column.Header !== "URL" && column.Header !== "Delete" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Name" ? setName(event.target.value) :
                        column.Header === "Status" ? setStatus(event.target.value) : setRepresentative(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

const Documents = () => {
  const [state] = useContext(Context);
  const [Documents, setDocuments] = useState([]);
  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMEstimate, setCRMEstimate] = useState([]);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [name, setName] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [Hover, setHover] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const crm_user_level = me?.readMe?.crm_user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { loading, data: people_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      status,
      representative,
      count: 0
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: all_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  useEffect(() => {
    setDocuments(data?.ListDocuments || [])
    setCRMJob(job_data?.ListJobs || []);
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);
  }, [people_data, crm_data, Representative_data, all_ticket_data,
    data, job_data, Proposal_data, Contract_data, Estimate_data,
    Photo_Gallery_data, Photo_data, service_data]);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = Documents.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setCustomers(newCustomers);
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_DOCUMENT,
    {
      onCompleted: ({ deleteDocument }) => {
        const newDocuments = Documents.filter(({ id }) => id !== deleteDocument);
        setCustomers(newDocuments);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const columns = compact([
    {
      Header: "Name",
      accessor: "documents_name"
    },
    {
      Header: "Status",
      accessor: "documents_status"
    },
    {
      Header: "Date",
      accessor: "documents_date"
    },
    {
      Header: "URL",
      accessor: "documents_url"
    },
    {
      Header: "Representative",
      accessor: "representative_id"
    },
    {
      Header: "Delete",
    }
  ]);

  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data, handleOpen } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell>
                {(CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id)?.length ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForContracts(row, index, "document_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "document_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand) ||
                  CheckForEstimates(row, index, "document_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand) ||
                  CheckForTickets(row, index, "document_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand) ||
                  CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell align="center">
                <>
                  <a
                    href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.people_id + "&documentid=" + row?.id}
                    target="_blank"
                  >
                    {row?.documents_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="center"><div>{row?.documents_status}</div>
              </TableCell>
              <TableCell align="center"><div>{row?.documents_date !== null ? moment(new Date(parseInt(row?.documents_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : ""}</div>
              </TableCell>
              <TableCell align="center"><div style={{ width: "200px" }}><a
                rel="noopener noreferrer"
                href={row?.documents_url}
                target="_blank"
              >
                View
              </a></div>
              </TableCell>
              <TableCell align="center"><div>{CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === row?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
              </TableCell>
              <TableCell align="center">
                {crm_user_level === "crm_admin" && (
                  <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                    }
                  }}>
                    <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} />
                  </DeleteButton>
                )}
              </TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <tbody>
                      {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                              <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ width: "100px" }}>
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                        {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell><div style={{ float: "left" }}>Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a target="_blank" href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                  </TableRow>
                                </Table>
                                <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                  <Table size="medium">
                                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10, width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <div style={{ width: "300px", float: "left" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRep) => CurrentRep?.id === CurrentCustomer?.representative_id).map(CurrentRep => { return <>{CurrentRep?.first_name} {CurrentRep?.last_name}</> })}</div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>)
                      })}
                      {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                      {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                      {CheckForJobs(row, index, "document_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "document_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "document_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "document_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForEstimates(row, index, "document_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                      {EstimateList(row, index, CRMEstimate, "document_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "document_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "document_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "document_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "document_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                      {CheckForTickets(row, index, "document_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                      {TicketList(row, index, CRMTickets, "document_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                    </tbody>
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <UploadFileOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={Documents || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setStatus={setStatus}
                  setRepresentative={setRepresentative}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={Documents} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Documents;
