import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import classNames from "classnames";
import PropTypes from "prop-types";
import { noop } from "lodash";
const moment = require("moment");

import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Hidden from "@mui/material/Hidden";
import Popper from "@mui/material/Popper";
import Divider from "@mui/material/Divider";
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import TaskOutlined from '@mui/icons-material/TaskOutlined';
import PhotoLibraryOutlined from '@mui/icons-material/PhotoLibraryOutlined';
import MailOutline from "@mui/icons-material/MailOutline";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CloseIcon from '@mui/icons-material/Close';

// @mui/icons-material
import Person from "@mui/icons-material/Person";

// core components
import Button from "components/CustomButtons/Button";
import DateRangeSelect from "components/DateRangeSelect";
import Select from "components/Select/Select";
import { SET_TOKEN, SELECT_COMPARE_OPTION } from "../../redux/constants";
import { Context } from "../../redux/store";
import { getPrevious } from "utils/format";
import { READ_ME } from "queries/users";
import { Badge as BaseBadge, badgeClasses } from '@mui/base/Badge';
import { styled } from '@mui/system';

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";

import UserSelect from "./UserSelect";
import Card from "components/Card/Card";

import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  MARK_ALL_NOTIFICATIONS_READ
} from "queries/customers";

const useStyles = makeStyles(styles);

const HeaderLinks = (props) => {
  const { rtlActive, Notifications, setNotifications } = props;
  const [compareOption, setCompareOption] = useState("no-compare");
  const [state, dispatch] = useContext(Context);
  const { data: me } = useQuery(READ_ME);
  const dateRangeString = state.customers?.dateRangeString;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const crm_user_level = me?.readMe?.crm_user_level;
  const [openProfile, setOpenProfile] = useState(null);
  const [showNotifications, setshowNotifications] = useState(false);
  const [Hover, setHover] = useState(null);

  let { data: notifications_data, refetch } = useQuery(GET_NOTIFICATIONS, {
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      user_id: me?.readMe?.id
    }
  });

  const [updateNotifications] = useMutation(MARK_NOTIFICATION_READ, {
    onCompleted: () => {
      refetch();
    }
  });

  const [updateAllNotifications] = useMutation(MARK_ALL_NOTIFICATIONS_READ, {
    onCompleted: () => {
      refetch();
    }
  });

  useEffect(() => {
    setNotifications(notifications_data?.getNotifications || []);
  }, [notifications_data]);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleOpenProfile = () => {
    window.location.href = "/admin/profile?customer=" + state.customers?.selected?.id;
  };
  const handleOpenNotification = () => {
    window.location.href = "/admin/notifications?customer=" + state.customers?.selected?.id;
  }
  const handleOpenPayment = () => {
    window.location.href = "/admin/payment?customer=" + state.customers?.selected?.id;
  };
  const handleOpenTutorials = () => {
    window.location.href = "/admin/tutorials?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMSettings = () => {
    window.location.href = "/admin/crm_settings?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMDataStructures = () => {
    window.location.href = "/admin/crm_data_structures?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMTableofContents = () => {
    window.location.href = "/admin/crm_table_of_contents?customer=" + state.customers?.selected?.id;
  };

  const handleOpenLatestUpdates = () => {
    window.location.href = "/admin/latestupdates?customer=" + state.customers?.selected?.id;
  };

  const handleLogout = () => {
    props.onSelect();
    handleCloseProfile();
    localStorage.setItem("tokenInfo", "");
    dispatch({ type: SET_TOKEN, payload: {} });
    window.location.href = "/auth/login";
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  const comparePeriod = getPrevious(dateRangeString, "period");
  const compareYear = getPrevious(dateRangeString, "year");
  const compareOptions = [
    { key: "compare-to", label: "Compare to", disabled: true },
    { key: "no-compare", label: "Don't Compare" },
    {
      key: "period",
      label: `Previous Period (${comparePeriod?.startDate} ~ ${comparePeriod?.endDate})`
    },
    {
      key: "year",
      label: `Previous Year (${compareYear?.startDate} ~ ${compareYear?.endDate})`
    }
  ];

  const blue = {
    500: '#007FFF',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Badge = styled(BaseBadge)(
    ({ theme }) => `
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    font-family: 'IBM Plex Sans', sans-serif;
    position: relative;
    display: inline-block;
    line-height: 1;
  
    & .${badgeClasses.badge} {
      z-index: auto;
      position: absolute;
      top: 0;
      right: 0;
      min-width: 22px;
      height: 22px;
      padding: 0 6px;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      white-space: nowrap;
      text-align: center;
      border-radius: 12px;
      background: ${blue[500]};
      box-shadow: 0px 4px 6x ${theme.palette.mode === 'dark' ? grey[900] : grey[300]};
      transform: translate(50%, -50%);
      transform-origin: 100% 0;
    }
    `,
  );

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <UserSelect onSelect={props.onSelect} />
      </div>
      <div className={managerClasses}>
        <DateRangeSelect onSelect={props.onSelect} isMobile={props.isMobile} />
        <Select
          options={compareOptions}
          selected={compareOption}
          onSelect={value => {
            dispatch({
              type: SELECT_COMPARE_OPTION,
              payload: value === "no-compare" ? null : value
            });
            setCompareOption(value);
          }}
          style={{ marginLeft: 30, width: 200 }}
        />
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleOpenProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleOpenNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Notifications"}
                    </MenuItem>
                    {(user_level === "master" ||
                      user_level === "customer" ||
                      user_level === "agency_customer" ||
                      user_level === "ad_consultant") && (
                        <MenuItem
                          onClick={handleOpenPayment}
                          className={dropdownItem}
                        >
                          {rtlActive ? "الملف الشخصي" : "Payment"}
                        </MenuItem>
                      )}
                    <MenuItem
                      onClick={handleOpenTutorials}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Tutorials"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleOpenCRMDataStructures}
                      className={dropdownItem}
                    >LinearCRM Data Structures</MenuItem>
                    {crm_user_level === "crm_admin" && (
                      <MenuItem
                        onClick={handleOpenCRMSettings}
                        className={dropdownItem}
                      >LinearCRM Settings</MenuItem>
                    )}
                    <MenuItem
                      onClick={handleOpenCRMTableofContents}
                      className={dropdownItem}
                    >LinearCRM Table of Contents</MenuItem>
                    <MenuItem
                      onClick={handleOpenLatestUpdates}
                      className={dropdownItem}
                    >Latest Updates</MenuItem>
                    <Divider light />
                    <MenuItem onClick={handleLogout} className={dropdownItem}>
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div className={managerClasses}>
          {Notifications?.filter((CurrentNotification) => CurrentNotification?.notificationread === 0)?.length ?
            <Badge badgeContent={Notifications?.filter((CurrentNotification) => CurrentNotification?.notificationread === 0)?.length} style={{ color: "#007FFF" }} onClick={() => setshowNotifications(showNotifications ? false : true)}>
              <NotificationsIcon style={{ color: "gray" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />
            </Badge>
            : <NotificationsIcon style={{ color: "gray" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />}

          {showNotifications ?
            <Card style={{ width: "600px", height: Notifications?.length === 0 ? "20%" : "80%", position: "fixed", overflowY: "hidden", right: "2%", borderRadius: "5%", padding: "20px", zIndex: 9999999 }}>
              <div style={{ overflowY: "auto", padding: "0", margin: "0" }}>
                {Notifications?.length === 0 ? <><CloseIcon style={{ float: "right", marginRight: "20px", cursor: "pointer" }} onClick={() => setshowNotifications(showNotifications ? false : true)} /><div style={{ clear: "both" }} /><div>No Notificiations at this time.</div></> : ""}
                {Notifications?.map((CurrentNotification, index) => {
                  return <>
                    {index === 0 ?
                      <><CloseIcon style={{ float: "right", marginRight: "40px", cursor: "pointer" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />
                        <div style={{ clear: "both" }} />
                        <div style={{ padding: "20px" }}><a href={"/admin/notifications?customer=" + state.customers?.selected?.id}>view all</a><a href="#" style={{ float: "right" }} onClick={(e) => {
                          e.preventDefault();
                          updateAllNotifications({
                            variables: {
                              customer_id: state.customers?.selected?.id,
                              user_id: me?.readMe?.id
                            }
                          });
                        }}>mark all read</a></div><div style={{ clear: "both" }} /></>
                      : ""}
                    <div style={{ cursor: "pointer", padding: "20px", backgroundColor: Hover === CurrentNotification?.id ? "#f4f4f5" : CurrentNotification?.notificationread === 0 ? "#EEF3F9" : "#fff", width: "100%" }} onMouseOver={() => { setHover(CurrentNotification?.id) }} onMouseOut={() => { setHover(null) }}
                      onClick={(e) => {
                        if (e?.target?.innerHTML != "mark read") {
                          updateNotifications({
                            variables: {
                              customer_id: state.customers?.selected?.id,
                              notification_id: CurrentNotification?.id
                            }
                          })
                          window.location = CurrentNotification?.detailPage;
                        }
                      }}>
                      <div style={{ float: "left", display: "inline-block" }}>
                        <div style={{ borderRadius: "50%", backgroundColor: "#007FFF", height: "40px", width: "40px", textAlign: "center", float: "left", display: "inline-block" }}>
                          {CurrentNotification?.image_object === "Customer" ? <HomeWorkOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "2px", marginTop: "3px" }} />
                            : CurrentNotification?.image_object === "Lead" ? <FlagOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                              : CurrentNotification?.image_object === "Person" ? <PermIdentityOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                : CurrentNotification?.image_object === "Task" ? <TaskAltOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                  : CurrentNotification?.image_object === "Deadline" ? <MenuOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                    : CurrentNotification?.image_object === "Meeting" ? <GroupAddOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                      : CurrentNotification?.image_object === "Email" ? <EmailOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                        : CurrentNotification?.image_object === "Call" ? <PhoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                          : CurrentNotification?.image_object === "Text" ? <SmartphoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                            : CurrentNotification?.image_object === "Job" ? <WorkOutlineOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                              : CurrentNotification?.image_object === "Contract" ? <RequestPageOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                : CurrentNotification?.image_object === "Proposal" ? <DescriptionOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                  : CurrentNotification?.image_object === "Document" ? <UploadFileOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                    : CurrentNotification?.image_object === "Estimate" ? <TaskOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                      : CurrentNotification?.image_object === "Photo" ? <PhotoLibraryOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                        : CurrentNotification?.image_object === "Ticket" ? <ConfirmationNumberOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                          : CurrentNotification?.image_object === "Customer Submitted Onboarding" || CurrentNotification?.image_object === "Onboarding Sent to Customer" ? <MailOutline style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                            : ""}
                        </div></div><div style={{ marginLeft: "30px", width: "70%", float: "left", display: "inline-block" }} >{CurrentNotification.message}</div>
                      <div style={{ clear: "both" }} />
                      <div style={{ marginTop: "10px", borderRadius: Notifications?.length - 1 === index ? "5%" : "0%" }}>{moment(new Date(parseInt(CurrentNotification.created_time))).fromNow()}{CurrentNotification?.notificationread === 0 ?
                        <a style={{ float: "right" }} href="#" onClick={() => {
                          updateNotifications({
                            variables: {
                              customer_id: state.customers?.selected?.id,
                              notification_id: CurrentNotification?.id
                            }
                          })
                        }}>mark read</a> : ""}</div></div>
                    {Notifications?.length - 1 !== index ? <hr style={{ color: "black", borderTop: "1px solid #000", padding: "0px", margin: "0px" }} /> : ""}
                  </>
                })}
              </div>
            </Card>
            : ""}
        </div>
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  history: PropTypes.shape(),
  onSelect: PropTypes.func,
  setNotifications: PropTypes.func,
  isMobile: PropTypes.bool
};

HeaderLinks.defaultProps = {
  onSelect: noop
};

export default HeaderLinks;
